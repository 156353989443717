import { useState, useEffect } from "react";
import {
  Typography,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Form,
  Button,
  Image,
  Empty,
  Tooltip,
  Divider,
} from "antd";
import { AiOutlineFieldTime, AiOutlineLogin } from "react-icons/ai";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import InfinityLoader from "../components/InfinityLoader";
import AttendanceCard from "../components/AttendanceCard";
import dummy2 from "../assets/dummy2.webp";
import Layout from "../layout/Layout";
import { useTranslation } from "react-i18next";
import {
  getEmployees,
  getEmployeesName,
  getUserDailyDetails,
  getUserScreenshots,
} from "../redux";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { base_url } from "../config/constants";

const Todos = () => {
  const { t } = useTranslation();
  const employees = useSelector((state) => state.employees.employeesName);
  const dispatch = useDispatch();
  const [snaps, setSnaps] = useState(null);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [resources, setResources] = useState(null);

  useEffect(async () => {
    dispatch(getEmployeesName());
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    // const allSnaps = await getUserScreenshots(
    //   values.date?.length === 2
    //     ? {
    //         user: values.user,
    //         startDate: moment(values.date[0]?._d).format("YYYY-MM-DD"),
    //         endDate: moment(values.date[1]?._d).format("YYYY-MM-DD"),
    //       }
    //     : {
    //         user: values.user,
    //       }
    // );
    // console.log(allSnaps);
    const allDetails = await getUserDailyDetails(
      values.date?.length === 2
        ? {
            user: values.user,
            startDate: moment(values.date[0]?._d).local().format("YYYY-MM-DD"),
            endDate: moment(values.date[1]?._d).local().format("YYYY-MM-DD"),
          }
        : {
            user: values.user,
          }
    );
    console.log(allDetails);
    setDetails(allDetails);
    // setSnaps(allSnaps?.result);
    setLoading(false);
  };
  useEffect(() => {
    let arr = employees?.employee?.concat(employees?.moderator);
    arr = arr?.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setResources(arr);
  }, [employees]);

  return (
    <Layout active={"checkins"}>
      {/* {detail === null ? (
        <InfinityLoader />
      ) : ( */}
      <div className="todo-main">
        <Typography.Title level={4}>
          {t("checkin-screenshots")}
        </Typography.Title>

        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={[30, 30]} align="bottom">
            <Col xs={24} md={8} lg={6}>
              <Form.Item label={t('select-resource')} name="user">
                <Select
                  style={{ width: "100%" }}
                  placeholder={t('select-resource')}
                >
                  {resources?.map((employee) => (
                    <Select.Option value={employee._id}>
                      {employee.name} {employee.adminType ? "(moderator)" : ""}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <Form.Item label={t("get-screenshots-by-range")} name="date">
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  disabledDate={(d) => {
                    return d?.isAfter(moment());
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <Form.Item>
                <Button type="primary" loading={loading} htmlType="submit">
                  {t("search")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {/* {details === null ? null : details?.dates?.length > 0 ? (
          details &&
          details?.dates?.map(
            (date) =>
              !loading && (
                <SnapComponent
                  // snap={snap}
                  // count={snaps?.length}
                  date={date}
                  checkins={details?.checkins}
                />
              )
          )
        ) : (
          <div className="empty">
            <Empty description="No Screenshots Found" />
          </div>
        )} */}
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <InfinityLoader />
          </div>
        ) : details === null ? null : details?.dates?.length > 0 ? (
          details &&
          details?.dates?.map(
            (date) =>
              !loading && (
                <SnapComponent
                  // snap={snap}
                  // count={snaps?.length}
                  date={date}
                  checkins={details?.checkins}
                />
              )
          )
        ) : (
          <div className="empty">
            <Empty description={t("no-checkins-found")} />
          </div>
        )}
      </div>
    </Layout>
  );
};

const SnapComponent = ({ date, checkins }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  const { t } = useTranslation();

  const btnShow = (date) => {
    let details = [];
    let time = 0;
    let flag = false;
    checkins.map((checkin) => {
      if (moment(checkin.date).format("YYYY-MM-DD") === date) {
        details.push(checkin);
        // setTotalHours(totalHours + checkin.hours);
        time = time + checkin.hours;
      }
    });
    // checkins.find((checkin) => {
    //   if (checkin.time.end === "Ongoing") {
    //     flag = true;
    //   }
    // });
    // if (flag) {
    //   let interval = 1000;
    //   time = time * 60 * 60 * 1000;
    //   setInterval(() => {
    //     time = time + interval;
    //     console.log(time);

    //     setTotalHours(
    //       new Date(time)
    //         .toISOString()
    //         .substr(11, 11 + 8)
    //         .split(".")[0]
    //     );
    //   }, interval);
      // if (localStorage.getItem("checkinId") === null) {
      //   clearInterval(int);
      //   setTotalHours(
      //     new Date(time)
      //       .toISOString()
      //       .substr(11, 11 + 8)
      //       .split(".")[0]
      //   );
      // }
    // } else {
      time = time * 60 * 60 * 1000;
      time = new Date(time)
        .toISOString()
        .substr(11, 11 + 8)
        .split(".")[0];

      setTotalHours(time);
    // }
    setData(details);
    setShow(!show);
  };
  return (
    <>
      <div className="screenshot-section">
        <div
          style={{
            width: "100%",
            border: "1px solid var(--borderLight",
            padding: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              width: "100%",
              marginBottom: "10px",
            }}
            onClick={() => btnShow(date)}
          >
            <span
              style={{
                color: "var(--text)",
                fontSize: "1.5rem",
              }}
            >
              {moment(date).local().format("dddd, DD-MMM-YYYY")}
            </span>
            {show ? (
              <IoIosArrowDropup
                style={{
                  color: "var(--text)",
                  fontSize: "2rem",
                  marginRight: "10px",
                }}
              />
            ) : (
              <IoIosArrowDropdown
                style={{
                  color: "var(--text)",
                  fontSize: "2rem",
                  marginRight: "10px",
                }}
              />
            )}
          </div>
          {show && (
            <>
              <Row
                gutter={[30, 15]}
                style={{
                  border: "1px solid var(--borderLight)",
                  borderRadius: "5px",
                  padding: "1rem",
                  margin: "1rem",
                  boxShadow: "var(--shadow)",
                }}
              >
                <Col span={24}>
                  <h2
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "var(--text)",
                      margin: "0",
                    }}
                  >
                    {t("checkin-details")}
                  </h2>
                  <Divider
                    style={{
                      margin: "0",
                      borderColor: "var(--text)",
                    }}
                  />
                </Col>
                <Col
                  xs={24}
                  md={24}
                  style={
                    {
                      // marginTop: "0.5rem",
                    }
                  }
                >
                  <Row>
                    <Col
                      md={11}
                      xs={24}
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <AiOutlineLogin
                        style={{
                          fontSize: "2rem",
                          color: "var(--text)",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "var(--text)",
                          marginRight: "0.3rem",
                        }}
                      >
                        {t("no-of-checkins")} : {data?.length || 0}
                      </span>
                    </Col>
                    <Col
                      md={11}
                      xs={24}
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <AiOutlineFieldTime
                        style={{
                          fontSize: "2rem",
                          color: "var(--text)",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "var(--text)",
                          marginRight: "0.3rem",
                        }}
                      >
                        {t("time")} :
                      </span>
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "var(--text)",
                        }}
                      >
                        {totalHours}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]} justify="space-around">
                    {data?.map((checkin, index) => {
                      return (
                        <Col xs={24} md={10} lg={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0.5rem",
                              border: "1px solid var(--borderLight)",
                              borderRadius: "5px",
                              boxShadow: "var(--shadow)",
                              minWidth: "170px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "var(--text)",
                              }}
                            >
                              {index + 1}
                            </span>
                            <span
                              style={{
                                fontSize: "0.8rem",
                                color: "var(--text)",
                              }}
                            >
                              {t("start")} : {checkin.time.start} (local)
                            </span>
                            <span
                              style={{
                                fontSize: "0.8rem",
                                color: "var(--text)",
                              }}
                            >
                              {t("end")} : {checkin.time.end} (local)
                            </span>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>

                <Col span={24}>
                  {/* <Row gutter={[30, 30]} style={{}}>
                  {snap?.snaps?.map((data) => (
                    <Col xs={24} md={8} lg={4}>
                      <Tooltip
                        title={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              Date:{" "}
                              {moment(data.screenshot.createdAt).format(
                                "DD-MM-YYYY"
                              )}
                            </span>
                            <span>
                              Time:{" "}
                              {moment(data.screenshot.createdAt).format(
                                "hh:mm A"
                              )}
                            </span>
                            <span>Address: {data.screenshot.address}</span>
                          </div>
                        }
                      >
                        <Image
                          loading="lazy"
                          src={
                            data?.imageCover
                              ? base_url + "/" + data?.imageCover
                              : base_url + "/" + data?.image
                          }
                          preview={{
                            src: base_url + "/" + data?.image,
                          }}
                        />
                      </Tooltip>
                    </Col>
                  ))}
                </Row> */}
                  {data.map((checkin, index) => {
                    console.log(checkin, "checkin");
                    return (
                      <Row gutter={[30, 30]}>
                        <Col
                          span={24}
                          style={{
                            marginTop: "1rem",
                          }}
                        >
                          <h2
                            style={{
                              fontWeight: "bold",
                              margin: "0",
                            }}
                          >
                            {t("screenshots-for-checkin")} {index + 1}
                          </h2>
                          <Divider
                            style={{
                              margin: "0",
                              borderColor: "var(--text)",
                            }}
                          />
                        </Col>
                        {checkin.snaps.map((data, index) => {
                          return (
                            <Col xs={24} md={8} lg={4}>
                              <Tooltip
                                title={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>
                                      {t("date")}:{" "}
                                      {moment(data.createdAt)
                                        .local()
                                        .format("DD-MM-YYYY")}
                                    </span>
                                    <span>
                                      {t("time")}:{" "}
                                      {moment(data.createdAt)
                                        .local()
                                        .format("hh:mm:ss a")}
                                    </span>
                                    {/* <span>
                                    Address: {data.screenshot.address}
                                  </span> */}
                                  </div>
                                }
                              >
                                <Image
                                  loading="lazy"
                                  src={
                                    data?.imageCover
                                      ? base_url + "/" + data?.imageCover
                                      : base_url + "/" + data?.image
                                  }
                                  preview={{
                                    src: base_url + "/" + data?.image,
                                  }}
                                />
                              </Tooltip>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Todos;
