import { useState, useEffect } from "react";
import {
  DatePicker,
  Divider,
  Select,
  Typography,
  Form,
  Row,
  Col,
  Input,
  Button,
  notification,
  Table,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
// import DatePicker from "antd";
import { useTranslation } from "react-i18next";
import Layout from "../layout/Layout";
import { getExpenses } from "../redux";
import InfinityLoader from "../components/InfinityLoader";
import AddExpense from "../components/modal/AddExpense";

const ProjectDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState("hi");
  const [month, setMonth] = useState();

  const { allExpenses, expenseTotal } = useSelector((state) => state.admin);
  const onMonthSelect = (value) => {
    setMonth(moment(value).format("MM"));
  };
  useEffect(() => {
    dispatch(getExpenses(month));
  }, [month]);


  return (
    <Layout active={"admin-expense"}>
      {allExpenses === null ? (
        <InfinityLoader />
      ) : (
        <div className="expense-main">
          <Row gutter={[0, 40]}>
            <Col xs={24} md={12} lg={8}>
              <div className="expense-card">
                <h2>{t('total-expenses-this-month')}</h2>
                <Typography.Title level={3} style={{ margin: "20px 0" }}>
                  {t('rs')} {expenseTotal.toLocaleString()}
                </Typography.Title>
              </div>
            </Col>
            <Col xs={24}>
              <div className="flex-between">
                <Typography.Title
                  level={3}
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  {t('company-monthly-expenses')}
                </Typography.Title>
                <div>
                  <AddExpense newexpense={true} />
                  <DatePicker
                    placeholder={t('select-month')}
                    onChange={onMonthSelect}
                    style={{ width: "150px", marginLeft: "20px" }}
                    picker={"month"}
                    disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>
              <Table
                size="middle"
                bordered={false}
                pagination={false}
                columns={[
                  {
                    title: t('date'),
                    dataIndex: "date",
                  },
                  {
                    title: t('amount'),
                    dataIndex: "amount",
                  },
                  {
                    title: t('description'),
                    dataIndex: "description",
                    width: "50%",
                  },
                  {
                    title: "",
                    dataIndex: "action",
                  },
                ]}
                dataSource={allExpenses?.map((expense) => ({
                  date: moment(expense.CreatedAt).format("DD-MMM-YYYY"),
                  amount: `${t('rs')} ` + expense.amount,
                  description: expense.description,
                  action: <AddExpense newexpense={false} data={expense} />,
                }))}
              />
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default ProjectDetail;
