import React from "react";
import { Column } from "@ant-design/charts";
import theme from "../../theme.json";

const ColumnChart = ({ data, yField, xField }) => {
  const config = {
    data,
    xField: xField,
    yField: yField,
    color: theme["primary-color"],
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: "gray",
        },
      },
    },
    meta: {
      cost: {
        alias: "cost",
      },
      hours: {
        alias: "hours",
      },
    },
  };
  return <Column {...config} className="coloumnChart" />;
};

// loading={Arr.length > 0 ? false : true}
export default ColumnChart;
