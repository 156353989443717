import { useState, useEffect } from "react";
import { Modal, Button, Typography, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createProject, updateProject, getEmployeesName } from "../../redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
const AddProject = ({ newProject, data, employees }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

 

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (newProject) {
      await dispatch(
        createProject({
          ...values,
          links: values.links?.split(","),
        })
      );
      form.resetFields();
    } else {
      await dispatch(
        updateProject({
          ...values,
          links: values.links?.split(","),
          id: data._id,
        })
      );
    }
    setLoading(false);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {newProject ? (
        <Button type="primary" className="addProjectBtn" onClick={showModal}>
          {t("add-project")}
        </Button>
      ) : (
        <Button
          type="primary"
          style={{ marginTop: "20px" }}
          onClick={showModal}
        >
          {t("update-project")}
        </Button>
      )}
      <Modal
        title={newProject ? t('new-project') : t('update-project')}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={"600px"}
        style={{
          top: "20px",
        }}
      >
        <Form
          layout="vertical"
          form={form}
          fields={
            data
              ? [
                  {
                    name: "title",
                    value: data.title,
                  },
                  {
                    name: "description",
                    value: data.description,
                  },
                  {
                    name: "assignedTo",
                    value: data.assignedTo,
                  },
                  {
                    name: "links",
                    value: data?.links?.join(","),
                  },
                ]
              : []
          }
          onFinish={onFinish}
        >
          <Form.Item label={t('assign-employees')} name={"assignedTo"}>
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              placeholder={t("select-employees")}
              style={{
                border: "1px solid var(--borderLight)",
              }}
            >
              {employees?.employee?.map((project) => (
                <Select.Option value={project._id}>
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('select-team-lead')} name={"teamLead"}>
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              placeholder={t("select-employees")}
              style={{
                border: "1px solid var(--borderLight)",
              }}
            >
              {employees?.employee?.map((project) => (
                <Select.Option value={project._id}>
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('project-name')}
            name={"title"}
            rules={[
              {
                required: true,
                message: t('project-name-required'),
              },
            ]}
            requiredMark={"optional"}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t('project-description')} name={"description"}>
            <Input.TextArea rows={4} style={{ resize: "none" }} />
          </Form.Item>

          <Form.Item
            label={t('project-links')}
            name={"links"}
          >
            <Input.TextArea rows={3} style={{ resize: "none" }} />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" loading={loading} type="primary">
              {newProject ? t('add-project') : t('update-project')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddProject;
