import React from "react";
import { Route, Redirect } from "react-router-dom";

const EmployeeRoutes = ({ component: Component, ...rest }) => {
  var id = localStorage.getItem("token");
  var userType = localStorage.getItem("userType");
  return (
    <Route
      {...rest}
      render={(props) =>
        id &&
        localStorage.hasOwnProperty("token") &&
        userType === "employee" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default EmployeeRoutes;
