import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import { TbLayoutDashboard, TbLogout } from "react-icons/tb";
import { AiOutlineProject, AiFillBook, AiOutlineBook } from "react-icons/ai";
import { GiPayMoney } from "react-icons/gi";
import {
  FiUsers,
  FiShield,
  FiAlignCenter,
  FiSend,
  FiHome,
} from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { MdOutlineAddModerator } from "react-icons/md";
import { TbReport } from "react-icons/tb";
import { BiTime } from "react-icons/bi";
import { GrInsecure } from "react-icons/gr";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import swal from "sweetalert";
const MainMenu = ({ active }) => {
  const { t } = useTranslation();
  const { SubMenu } = Menu;
  const history = useHistory();
  const userType = localStorage.getItem("userType");
  const moderator = JSON.parse(localStorage.getItem("moderator"));
  const roles = moderator?.roles?.roles;
  useEffect(() => {}, [roles]);

  return (
    <Menu
      theme="dark"
      mode={"inline"}
      defaultSelectedKeys={[active]}
      style={{
        background: "var(--sidebar)",
        minHeight: "83vh",
      }}
    >
      {userType === "admin" && (
        <>
          <Menu.Item
            key="admin-dashboard"
            className={"sidebar-menu"}
            icon={<FiHome className="menu-icon" />}
            onClick={() => history.push("/admin/dashboard")}
          >
            {t("dashboard")}
          </Menu.Item>
          <Menu.Item
            key="admin-projects"
            className={"sidebar-menu"}
            icon={<AiOutlineProject className="menu-icon" />}
            onClick={() => history.push("/admin/projects")}
          >
            {t("projects")}
          </Menu.Item>
          <Menu.Item
            key="admin-employees"
            className={"sidebar-menu"}
            icon={<FiUsers className="menu-icon" />}
            onClick={() => history.push("/admin/employees")}
          >
            {t("employees")}
          </Menu.Item>
          <Menu.Item
            key="admin-expense"
            className={"sidebar-menu"}
            icon={<GiPayMoney className="menu-icon" />}
            onClick={() => history.push("/admin/expenses")}
          >
            {t("expenses")}
          </Menu.Item>
          <Menu.Item
            key="admin-report"
            className={"sidebar-menu"}
            icon={<TbReport className="menu-icon" />}
            onClick={() => history.push("/admin/reports")}
          >
            {t("reports")}
          </Menu.Item>
          <Menu.Item
            key="admin-moderator"
            className={"sidebar-menu"}
            icon={<MdOutlineAddModerator className="menu-icon" />}
            onClick={() => history.push("/admin/moderators")}
          >
            {t("moderators")}
          </Menu.Item>

          <Menu.Item
            key="checkins"
            className={"sidebar-menu"}
            icon={<BiTime className="menu-icon" />}
            onClick={() => history.push("/admin/checkins")}
          >
            {t("checkins")}
          </Menu.Item>
          <Menu.Item
            key="attendance"
            className={"sidebar-menu"}
            icon={<FiUsers className="menu-icon" />}
            onClick={() => history.push("/admin/attendance")}
          >
            {t("attendance")}
          </Menu.Item>
          <Menu.Item
            key="roles"
            className={"sidebar-menu"}
            icon={<FiShield className="menu-icon" />}
            onClick={() => history.push("/admin/roles")}
          >
            {t("roles")}
          </Menu.Item>
          <Menu.Item
            key="logs"
            className={"sidebar-menu"}
            icon={<FiAlignCenter className="menu-icon" />}
            onClick={() => history.push("/admin/logs")}
          >
            {t("logs")}
          </Menu.Item>
          {/* <Menu.Item
            key="notification"
            className={"sidebar-menu"}
            icon={<FiSend className="menu-icon" />}
            onClick={() => history.push("/admin/createNotification")}
          >
            Notification
          </Menu.Item> */}
        </>
      )}

      {userType === "moderator" && (
        <>
          <Menu.Item
            key="checkins"
            className={"sidebar-menu"}
            icon={<BiTime className="menu-icon" />}
            onClick={() => history.push("/moderator/checkins")}
          >
            {t("checkin")}
          </Menu.Item>
          {(roles?.includes("projects") !== false ||
            roles?.includes("add-projects") !== false ||
            roles?.includes("project-summary") !== false) && (
            <Menu.Item
              key="admin-projects"
              className={"sidebar-menu"}
              icon={<AiOutlineProject className="menu-icon" />}
              onClick={() => history.push("/admin/projects")}
            >
              {t("projects")}
            </Menu.Item>
          )}
          {(roles?.includes("employees") !== false ||
            roles?.includes("add-employee") !== false ||
            roles?.includes("employee-summary") !== false ||
            roles?.includes("update-employee") !== false) && (
            <Menu.Item
              key="admin-employees"
              className={"sidebar-menu"}
              icon={<FiUsers className="menu-icon" />}
              onClick={() => history.push("/admin/employees")}
            >
              {t("employees")}
            </Menu.Item>
          )}
          {(roles?.includes("expenses") !== false ||
            roles?.includes("add-expense") !== false ||
            roles?.includes("update-expense") !== false) && (
            <Menu.Item
              key="admin-expense"
              className={"sidebar-menu"}
              icon={<GiPayMoney className="menu-icon" />}
              onClick={() => history.push("/admin/expenses")}
            >
              {t("expenses")}
            </Menu.Item>
          )}
          {roles?.includes("reports") !== false && (
            <Menu.Item
              key="admin-report"
              className={"sidebar-menu"}
              icon={<TbReport className="menu-icon" />}
              onClick={() => history.push("/admin/reports")}
            >
              {t("reports")}
            </Menu.Item>
          )}
          {(roles?.includes("moderators") !== false ||
            roles?.includes("add-moderator")) && (
            <Menu.Item
              key="admin-moderator"
              className={"sidebar-menu"}
              icon={<MdOutlineAddModerator className="menu-icon" />}
              onClick={() => history.push("/admin/moderators")}
            >
              {t("moderators")}
            </Menu.Item>
          )}

          {(roles?.includes("checkins") !== false ||
            roles?.includes("view-checkins") !== false ||
            roles?.includes("view-snaps")) && (
            <Menu.Item
              key="checkins"
              className={"sidebar-menu"}
              icon={<BiTime className="menu-icon" />}
              onClick={() => history.push("/admin/checkins")}
            >
              {t("checkins")}
            </Menu.Item>
          )}
          {roles?.includes("attendance") !== false && (
            <Menu.Item
              key="attendance"
              className={"sidebar-menu"}
              icon={<FiUsers className="menu-icon" />}
              onClick={() => history.push("/admin/attendance")}
            >
              {t("attendance")}
            </Menu.Item>
          )}
          {roles?.includes("roles") !== false && (
            <Menu.Item
              key="roles"
              className={"sidebar-menu"}
              icon={<FiShield className="menu-icon" />}
              onClick={() => history.push("/admin/roles")}
            >
              {t("roles")}
            </Menu.Item>
          )}
        </>
      )}

      {userType === "employee" && (
        <>
          <Menu.Item
            key="checkins"
            className={"sidebar-menu"}
            icon={<BiTime className="menu-icon" />}
            onClick={() => history.push("/employee/checkins")}
          >
            {t("checkin")}
          </Menu.Item>
          <Menu.Item
            key="admin-projects"
            className={"sidebar-menu"}
            icon={<AiOutlineProject className="menu-icon" />}
            onClick={() => history.push("/employee/projects")}
          >
            {t("projects")}
          </Menu.Item>
          <Menu.Item
            key="Todos"
            className={"sidebar-menu"}
            icon={<AiOutlineBook className="menu-icon" />}
            onClick={() => history.push("/todo")}
          >
            {t("todos")}
          </Menu.Item>
          <Menu.Item
            key="employee-summary"
            className={"sidebar-menu"}
            icon={<FiUsers className="menu-icon" />}
            onClick={() => history.push("/employee/summary")}
          >
            {t("summary")}
          </Menu.Item>
        </>
      )}
      <Menu.Item
        key="logout"
        className={"sidebar-menu logout-menu"}
        icon={<TbLogout className="menu-icon" />}
        onClick={() => {
          if (
            localStorage.getItem("userType") === "employee" &&
            localStorage.getItem("checkinId")
          ) {
            return swal({
              title: "Warning",
              text: "You are currently checked in. You will have to check out in order to proceed.",
              icon: "warning",
            });
          }
          localStorage.clear();
          history.push("/");
        }}
        style={{
          position: userType === 'admin' ? 'relative' : 'absolute',
          bottom: userType === 'admin' ? '-16px' : '16px',
        }}
      >
        {t("logout")}
      </Menu.Item>
    </Menu>
  );
};

export default MainMenu;
