import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useRef, useCallback } from "react";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale);
const PDFDoghnut = (props) => {
  let base64Img;
  const ref = useRef(null);
//   const image = useCallback(() => {
//     base64Img = ref.current.toBase64Image();
//   }, []);
base64Img = ref.current.toBase64Image();
  const data = {
    labels: ["Whatsapp", "Youtube", "Others"],
    datasets: [
      {
        label: "percentage",
        data: [30, 30, 40],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      {/* <button onClick={image}>x</button> */}
      <Doughnut ref={ref} data={data} />
    </div>
  );
};

export default PDFDoghnut;
