import swal from "sweetalert";
import { notification } from "antd";
import { publicAPI, privateAPI, attachToken } from "../../config/constants";
import { GET_ROLES } from "../types/generalTypes";

export const getRoles = () => {
  return async (dispatch) => {
    try {
      console.log('getRoles')
      attachToken();
      const res = await privateAPI.get(`/roles/getRoles`);
      if (res) {
        dispatch({
          type: GET_ROLES,
          payload: res.data,
        });
    }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const addRole = (data) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/roles/assignRoles`, data);
      if (res) {
        notification.success({
          message: "Role Added Successfully",
        });
        dispatch(getRoles());
      }
    } catch (err) {
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};
