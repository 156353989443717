import {
  GET_NOTIFICATIONS,
  GET_NOTICOUNT,
  GET_ALLNOTIFICATIONS,
} from "../types/generalTypes";
import swal from "sweetalert";
import { notification } from "antd";
import { publicAPI, privateAPI, attachToken } from "../../config/constants";

export const getNotificationCount = () => async (dispatch) => {
  try {
    attachToken();
    const res = await privateAPI.get(`/notifications/get-counts`);
    if (res) {
      dispatch({
        type: GET_NOTICOUNT,
        payload: res.data,
      });
    }
  } catch (error) {
    swal("", error?.response?.data?.message || "Server Error", "error");
  }
};

export const getNotifications = () => async (dispatch) => {
  try {
    attachToken();
    const res = await privateAPI.get(`/notifications/getUnread`);
    if (res) {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data,
      });
    }
  } catch (error) {
    swal("", error?.response?.data?.message || "Server Error", "error");
  }
};

export const getAllNotifications = () => async (dispatch) => {
  try {
    attachToken();
    const res = await privateAPI.get(`/notifications/get-all`);
    if (res) {
      dispatch({
        type: GET_ALLNOTIFICATIONS,
        payload: res.data,
      });
    }
  } catch (error) {
    swal("", error?.response?.data?.message || "Server Error", "error");
  }
};

export const createNotification = (message) => async (dispatch) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/notifications/create`, { message });
    if (res) {
      // notification.success({
      //   message: "Notification Created",
      //   description: "Notification Created Successfully",
      // });
      swal("Success", "Notification Created Successfully", "success");
    }
  } catch (error) {
    swal("", error?.response?.data?.message || "Server Error", "error");
  }
};
