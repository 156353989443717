import { useState, useEffect } from "react";
import { Table, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { deleteRemark } from "../../redux";
import AddRemarks from "../modal/AddRemarks";

const ProjectDetailTable = ({ userType, detail, getData }) => {
  const [TableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    let data = [];
    detail?.timeline?.map((timeline) => {
      data.push({
        name: timeline?.user?.name,
        hours: timeline?.hours,
        cost: "Rs. " + timeline?.cost?.toFixed(0),
        remarks: !timeline?.remarks ? (
          "-"
        ) : (
          <Tooltip placement="top" title={timeline?.remarks}>
            {timeline?.remarks?.length > 20
              ? `${timeline?.remarks?.slice(0, 20)}...`
              : timeline?.remarks}
          </Tooltip>
        ),
        date: moment(timeline?.CreatedAt).local().format("D-MM-YYYY"),
        time: moment(timeline?.CreatedAt).local().format("hh:mm a"),
        actions: (
          <div className="action-div">
            <AddRemarks state={timeline} id={timeline?._id} getData={getData} />
            <MdDelete
              className="icon delete"
              onClick={async () => {
                let res = await dispatch(
                  deleteRemark({
                    id: timeline?._id,
                  })
                );
                if (res) {
                  getData();
                }
              }}
            />
          </div>
        ),
      });
    });
    setTableData(data);
  }, [detail]);

  const columns = [
    {
      title: t("date"),
      dataIndex: "date",
    },
    {
      title: t("submit-time"),
      dataIndex: "time",
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("hours"),
      dataIndex: "hours",
    },

    {
      title: t("cost"),
      dataIndex: "cost",
    },
    {
      title: t("remarks"),
      dataIndex: "remarks",
    },
  ];

  const Usercolumn = [
    {
      title: t("date"),
      dataIndex: "date",
    },
    {
      title: t("submit-time"),
      dataIndex: "time",
    },
    {
      title: t("hours"),
      dataIndex: "hours",
    },
    {
      title: t("cost"),
      dataIndex: "remarks",
    },
    {
      title: t('actions'),
      dataIndex: "actions",
    },
  ];

  return (
    <div>
      <Table
        sticky
        dataSource={TableData}
        className="project-table"
        columns={userType === "admin" ? columns : Usercolumn}
        scroll={{ x: 700 }}
        pagination={TableData.length > 10 ? true : false}
      />
    </div>
  );
};

export default ProjectDetailTable;
