import { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import { Link, useHistory, Redirect, NavLink } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

import { authLogin, forgotPassword } from "../redux";
import Header from "../components/Header";
import logo from "../assets/Logo.png";
const ForgotPassword = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    const bool = await forgotPassword(values);
    setLoading(false);
    if (bool) {
      history.push("/resetPassword", { email: values.email });
    }
  };
  let type = localStorage.getItem("userType");

  if (localStorage.hasOwnProperty("token") && type === "employee") {
    return <Redirect to="/employee/checkins" />;
  } else if (
    localStorage.hasOwnProperty("token") &&
    (type === "admin" || type === "moderator")
  ) {
    return <Redirect to="/admin/projects" />;
  } else {
    return (
      <>
        <div className="signin">
          <Form
            name="login"
            className="login-form"
            layout="vertical"
            onFinish={onFinish}
          >
            {/* <img src={logo} / > */}
            <img
              src={logo}
              style={{
                width: 200,
                // marginRight: 20,
              }}
            />
            <h2>{t("forgot-password")}</h2>
            {/* <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}>
                      <div style={{
                        width: "76%",
                      }}>
                      </div>
                    </div> */}
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("invalid-email"),
                },
                {
                  required: true,
                  message: t("email-required"),
                },
              ]}
              label={t("email")}
            >
              <Input autoComplete="off" placeholder={t("email")} />
            </Form.Item>
            {/* <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is Required",
                },
              ]}
              label="Password"
            >
              <Input type="password" placeholder="Password..." />
            </Form.Item> */}

            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {t('find-account')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  }
};

export default ForgotPassword;
