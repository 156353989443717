import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

let Structure = [
  {
    row: 1,
    columnstart: 5.5,
    height: 60,
    textStart: 8.5,
    textHeight: 67,
  },
  {
    row: 1,
    columnstart: 34.5,
    height: 60,
    textStart: 37.5,
    textHeight: 67,
  },
  {
    row: 1,
    columnstart: 63.5,
    height: 60,
    textStart: 66.5,
    textHeight: 67,
  },
  {
    row: 1,
    columnstart: 92.5,
    height: 60,
    textStart: 95.5,
    textHeight: 67,
  },
  {
    row: 1,
    columnstart: 121.5,
    height: 60,
    textStart: 124.5,
    textHeight: 67,
  },
  {
    row: 1,
    columnstart: 150.5,
    height: 60,
    textStart: 153.5,
    textHeight: 67,
  },
  {
    row: 1,
    columnstart: 179.5,
    height: 60,
    textStart: 182.5,
    textHeight: 67,
  },
];

const AttendancePdf = (month, name) => {
  var doc = new jsPDF();
  let red = "#F1948A";
  let gray = "#E5E7E9";

  doc.setTextColor("#0B5394");
  doc.setFontSize(24);
  let textX =
    (doc.internal.pageSize.getWidth() -
      doc.getTextWidth(`${month} Attendance`)) /
    2;
  doc.text(`${month} Attendance`, textX, 16);

  doc.setTextColor("#000000");
  doc.setFontSize(14);
  doc.text(10, 35, "Employee Name : ");
  doc.text(52, 35, `${name}`);

  doc.text(10, 45, "Total Present Days : ");
  doc.text(57, 45, `${"24"}`);

  doc.text(128, 45, "Total Absent Days : ");
  doc.text(172, 45, `${"6"}`);

  Structure?.map((stc, i) => {
    doc.setFillColor(gray);
    doc.roundedRect(stc?.columnstart, stc?.height, 25, 25, 2, 2, "F");
    doc.setTextColor("#566573");
    doc.setFontSize(16);
    doc.text(stc?.textStart, stc?.textHeight, `0${i + 1}`);
    doc.setFontSize(10);
    let weekDayHeight = stc?.textHeight + 6;
    doc.text(stc?.textStart, weekDayHeight, "Sunday");
  });

  doc.save("pdfname.pdf");
};

export default AttendancePdf;
