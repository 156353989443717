import { Col, Divider, Row, Select, Table } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import Layout from "../layout/Layout";
import { getDashboard } from "../redux";
import { useDispatch } from "react-redux";
import "../styles/pages/Dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [todayAttendance, setTodayAttendance] = useState();
  const [yesterdayAttendance, setYesterdayAttendance] = useState();
  const [todayAttType, setTodayAttType] = useState("all");
  const [yesterdayAttType, setYesterdayAttType] = useState("all");
  const [totalCosting, setTotalCosting] = useState();
  const [thisMonthCosting, setThisMonthCosting] = useState();
  const [lastMonthCosting, setLastMonthCosting] = useState();
  const [thisMonthAllCostings, setThisMonthAllCostings] = useState();
  const [lastMonthAllCostings, setLastMonthAllCostings] = useState();
  const fetchData = async () => {
    setLoading(true);
    const res = await getDashboard();
    setData(res);
    setLoading(false);
  };
  const ref = useRef();
  const barOptions = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };

  const attendanceData = {
    labels: [t("total"), t("today"), t("yesterday")],
    datasets: [
      {
        minBarLength: 6,
        label: t("employees"),
        data: [
          data?.totalEmployess.length || 0,
          data?.presentToday.length || 0,
          data?.presentYesterday.length || 0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.7)",
          "rgba(54, 162, 235, 0.7)",
          "rgba(255, 206, 86, 0.7)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
      },
    ],
  };

  const progressData = {
    labels: [t("total"), t("today"), t("yesterday")],
    datasets: [
      {
        minBarLength: 6,
        label: t("projects"),
        data: [
          data?.allProjects.length || 0,
          data?.todayWorkingProjects.length || 0,
          data?.yesterdayWorkingProjects.length || 0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.7)",
          "rgba(54, 162, 235, 0.7)",
          "rgba(255, 206, 86, 0.7)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
      },
    ],
  };

  const costingData = {
    labels: [t("total"), t("this-month"), t("last-month")],
    datasets: [
      {
        minBarLength: 6,
        label: t("costing"),
        data: [totalCosting || 0, thisMonthCosting || 0, lastMonthCosting || 0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.7)",
          "rgba(54, 162, 235, 0.7)",
          "rgba(255, 206, 86, 0.7)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
      },
    ],
  };

  const attendanceCoumns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("designation"),
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: t("attendance"),
      dataIndex: "attendance",
      key: "attendance",
    },
  ];

  const progressColumns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
    },
  ];
  const costingColumns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("hours"),
      dataIndex: "hours",
      key: "hours",
    },
    {
      title: t("costing"),
      dataIndex: "costing",
      key: "costing",
    },
  ];
  const setTodayAttendanceD = async () => {
    if (data) {
      let arr = data.presentToday.concat(data.absentToday);
      arr = arr?.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setTodayAttendance(arr);
    }
  };
  const setYesterdayAttendanceD = async () => {
    if (data) {
      let arr = data.presentYesterday.concat(data.absentYesterday);
      arr = arr?.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setYesterdayAttendance(arr);
    }
  };
  const todayAttFilter = (type) => {
    setTodayAttType(type);
    if (type === "all") {
      setTodayAttendanceD();
    } else if (type === "present") {
      setTodayAttendance(
        data.presentToday.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    } else if (type === "absent") {
      setTodayAttendance(
        data.absentToday.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    }
  };
  const yesterdayAttFilter = (type) => {
    setYesterdayAttType(type);
    if (type === "all") {
      setYesterdayAttendanceD();
    } else if (type === "present") {
      setYesterdayAttendance(
        data.presentYesterday.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    } else if (type === "absent") {
      setYesterdayAttendance(
        data.absentYesterday.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    }
  };
  const setCostings = () => {
    let totalCosting = 0;
    let thisMonth = 0;
    let lastMonth = 0;
    let thisMonthAll = [];
    let lastMonthAll = [];
    let currentMonth = new Date().getMonth();
    if (data) {
      data.projectCostings.map((item) => {
        item.costing.map((cost, index) => {
          totalCosting = totalCosting + cost.totalCost;
          if (index === currentMonth) {
            thisMonth = thisMonth + cost.totalCost;
            thisMonthAll.push({
              name: item.title,
              costs: {
                cost: cost.totalCost,
                hours: cost.totalHours,
              },
            });
          }
          if (index === currentMonth - 1) {
            lastMonth = lastMonth + cost.totalCost;
            lastMonthAll.push({
              name: item.title,
              costs: {
                cost: cost.totalCost,
                hours: cost.totalHours,
              },
            });
          }
        });
      });
      setTotalCosting(totalCosting);
      setThisMonthCosting(thisMonth);
      setLastMonthCosting(lastMonth);
      setThisMonthAllCostings(
        thisMonthAll.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
      setLastMonthAllCostings(
        lastMonthAll.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      setTodayAttendanceD();
      setYesterdayAttendanceD();
      setCostings();
    }
  }, [data]);

  return (
    <Layout active={"admin-dashboard"}>
      <div className="admin-dashboard-container">
        <div className="summary">
          <Row gutter={[30, 30]} justify="space-between">
            <Col md={7} xs={24} className="card">
              <div>
                <span className="card-top">{t("attendance")}</span>
                <div className="card-bottom">
                  <Bar data={attendanceData} options={barOptions} ref={ref} />
                </div>
              </div>
            </Col>

            <Col md={7} xs={24} className="card">
              <div>
                <span className="card-top">{t("projects-progress")}</span>
                <div className="card-bottom">
                  <Bar data={progressData} options={barOptions} ref={ref} />
                </div>
              </div>
            </Col>

            <Col md={7} xs={24} className="card">
              <div>
                <span className="card-top">{t("projects-costings")}</span>
                <div className="card-bottom">
                  <Bar data={costingData} options={barOptions} ref={ref} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="attendance-analytics">
          <Divider>
            <h3>{t("attendance")}</h3>
          </Divider>
          <Row justify="space-between" gutter={[30, 30]}>
            <Col md={12} xs={24}>
              <div className="today-attendance">
                <div className="table-header">
                  <h4>{t("today-attendance")}</h4>
                  <Select
                    value={todayAttType}
                    onChange={todayAttFilter}
                    style={{
                      width: 200,
                    }}
                  >
                    <Select.Option value="all">{t("all")}</Select.Option>
                    <Select.Option value="present">
                      {t("present")}
                    </Select.Option>
                    <Select.Option value="absent">{t("absent")}</Select.Option>
                  </Select>
                </div>
                <Divider
                  style={{
                    margin: "5px 0",
                    borderBottom: "1px solid var(--text)",
                  }}
                />
                <div className="table-body">
                  <Table
                    columns={attendanceCoumns}
                    pagination={{ defaultPageSize: 5 }}
                    dataSource={
                      todayAttendance
                        ? todayAttendance.map((val, ind) => {
                            return {
                              key: ind,
                              name: val.name,
                              designation: val.designation,
                              attendance: val.attendance
                                ? val.attendance
                                : "Present",
                            };
                          })
                        : []
                    }
                  />
                </div>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <div className="today-attendance">
                <div className="table-header">
                  <h4>{t("yesterday-attendance")}</h4>
                  <Select
                    value={yesterdayAttType}
                    onChange={yesterdayAttFilter}
                    style={{
                      width: 200,
                    }}
                  >
                    <Select.Option value="all">{t("all")}</Select.Option>
                    <Select.Option value="present">
                      {t("present")}
                    </Select.Option>
                    <Select.Option value="absent">{t("absent")}</Select.Option>
                  </Select>
                </div>
                <Divider
                  style={{
                    margin: "5px 0",
                    borderBottom: "1px solid var(--text)",
                  }}
                />
                <div className="table-body">
                  <Table
                    columns={attendanceCoumns}
                    pagination={{ defaultPageSize: 5 }}
                    dataSource={
                      yesterdayAttendance
                        ? yesterdayAttendance.map((val, ind) => {
                            return {
                              key: ind,
                              name: val.name,
                              designation: val.designation,
                              attendance: val.attendance
                                ? val.attendance
                                : "Present",
                            };
                          })
                        : []
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="progress-analytics">
          <Divider>
            <h3>{t("projects-progress")}</h3>
          </Divider>
          <Row justify="space-between" gutter={[30, 30]}>
            <Col md={12} xs={24}>
              <div className="today-progress">
                <div className="table-header">
                  <h4>{t("today-progress")}</h4>
                </div>
                <Divider
                  style={{
                    margin: "5px 0",
                    borderBottom: "1px solid var(--text)",
                  }}
                />
                <div className="table-body">
                  <Table
                    columns={progressColumns}
                    pagination={{ defaultPageSize: 5 }}
                    dataSource={
                      data &&
                      data.todayWorkingProjects?.map((val, ind) => {
                        return {
                          key: ind,
                          name: val.title,
                          description: val.description ? val.description : "-",
                        };
                      })
                    }
                  />
                </div>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <div className="today-progress">
                <div className="table-header">
                  <h4>{t("yesterday-progress")}</h4>
                </div>
                <Divider
                  style={{
                    margin: "5px 0",
                    borderBottom: "1px solid var(--text)",
                  }}
                />
                <div className="table-body">
                  <Table
                    columns={progressColumns}
                    pagination={{ defaultPageSize: 5 }}
                    dataSource={
                      data &&
                      data.yesterdayWorkingProjects?.map((val, ind) => {
                        return {
                          key: ind,
                          name: val.title,
                          description: val.description ? val.description : "-",
                        };
                      })
                    }
                  />
                </div>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <div className="last7day-progress">
                <div className="table-header">
                  <h4>{t("no-work-last-7-days")}</h4>
                </div>
                <Divider
                  style={{
                    margin: "5px 0",
                    borderBottom: "1px solid var(--text)",
                  }}
                />
                <div className="table-body">
                  <Table
                    columns={progressColumns}
                    pagination={{ defaultPageSize: 5 }}
                    dataSource={
                      data &&
                      data.noWorkLast7DaysProject?.map((val, ind) => {
                        return {
                          key: ind,
                          name: val.title,
                          description: val.description ? val.description : "-",
                        };
                      })
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="costing-analytics">
          <Divider>
            <h3>{t("projects-costings")}</h3>
          </Divider>
          <Row justify="space-between" gutter={[30, 30]}>
            <Col md={12} xs={24}>
              <div className="today-costing">
                <div className="table-header">
                  <h4>
                    {t("this-month")} {t("costing")}
                  </h4>
                </div>
                <Divider
                  style={{
                    margin: "5px 0",
                    borderBottom: "1px solid var(--text)",
                  }}
                />
                <div className="table-body">
                  <Table
                    columns={costingColumns}
                    pagination={{ defaultPageSize: 5 }}
                    dataSource={
                      thisMonthAllCostings &&
                      thisMonthAllCostings.map((val, ind) => {
                        return {
                          key: ind,
                          name: val.name,
                          costing: val.costs.cost,
                          hours: val.costs.hours,
                        };
                      })
                    }
                  />
                </div>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <div className="today-costing">
                <div className="table-header">
                  <h4>
                    {t("last-month")} {t("costing")}
                  </h4>
                </div>
                <Divider
                  style={{
                    margin: "5px 0",
                    borderBottom: "1px solid var(--text)",
                  }}
                />
                <Table
                  columns={costingColumns}
                  pagination={{ defaultPageSize: 5 }}
                  dataSource={
                    lastMonthAllCostings &&
                    lastMonthAllCostings.map((val, ind) => {
                      return {
                        key: ind,
                        name: val.name,
                        costing: val.costs.cost,
                        hours: val.costs.hours,
                      };
                    })
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
