import * as types from "../types/generalTypes";

const initialState = {
  allExpenses: null,
  expenseTotal: 0,
  moderator: null,
};

const cpuReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ADMIN_EXPENSE:
      return {
        ...state,
        allExpenses: payload.expenses,
        expenseTotal: payload.expenseTotal,
      };
    case types.MODERATOR_LOGIN:
      return {
        ...state,
        moderator: payload,
      };
    default:
      return state;
  }
};

export default cpuReducer;
