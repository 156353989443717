import {
  Button,
  DatePicker,
  Empty,
  notification,
  Row,
  Col,
  Divider,
} from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../layout/Layout";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { AiOutlineFieldTime, AiOutlineLogin } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import {
  addOfflineScreenshot,
  addScreenshot,
  endTask,
  getUserDailyDetails,
  getUserTimelineScreenshots,
  startTask,
} from "../redux";
import "../styles/pages/checkins.css";
import dataURItoBlob from "../service/dataURItoBlob";
import InfinityLoader from "../components/InfinityLoader";
import { t } from "i18next";

const Checkins = ({
  intervalState,
  setIntervalState,
  videoEL,
  setVideoEl,
  TrackState,
  setTrackState,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [date, setDate] = useState({
    start: null,
    end: null,
  });
  const [data, setData] = useState();
  const dispatch = useDispatch();
  let type = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");
  const checkins = useSelector((state) => state.project.checkins);

  const onDateChange = (date, dateString) => {
    setDate({
      start: dateString[0],
      end: dateString[1],
    });
  };

  const fetchCheckins = async () => {
    setLoading2(true);
    let allDetails = null;
    if (date.start && date.end) {
      allDetails = await getUserDailyDetails({
        user: userId,
        startDate: moment(date.start).format("YYYY-MM-DD"),
        endDate: moment(date.end).format("YYYY-MM-DD"),
      });
      setData(allDetails);
    } else {
      allDetails = await getUserDailyDetails({ user: userId });
      setData(allDetails);
    }
    setLoading2(false);
  };

  function stopStreamedVideo() {
    // let videoEL = document.getElementById("video");
    // console.log(videoEL);
    if (videoEL) {
      const streamVar = videoEL.srcObject;
      if (streamVar) {
        const tracks = streamVar.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });
      }
      clearInterval(intervalState);
    }
    // console.log("clear interval");
    // videoEL.srcObject = null;
  }
  const displayMediaOptions = {
    video: {
      cursor: "always",
    },
    audio: false,
  };
  const sendOfflineScreenshots = () => {
    let offlineScreenshots = localStorage.getItem("offlineScreenshots")
      ? JSON.parse(localStorage.getItem("offlineScreenshots"))
      : [];

    if (offlineScreenshots.length > 0) {
      // .format("YYYY-MM-DD--HH-MM")

      let formData = new FormData();
      offlineScreenshots?.map((data) => {
        const blob = dataURItoBlob(data.file);

        var file = new File([blob], `${data.date}.jpg`, {
          type: "application/octet-stream",
        });
        formData.append("screenshot", file);
      });
      formData.append("checkinId", localStorage.getItem("checkinId"));
      addOfflineScreenshot(formData);
    }
  };
  const setCapturetoLocal = (payload) => {
    let offlineScreenshots = localStorage.getItem("offlineScreenshots")
      ? JSON.parse(localStorage.getItem("offlineScreenshots"))
      : [];

    // console.log("offlineScreenshots", offlineScreenshots);

    localStorage.setItem(
      "offlineScreenshots",
      JSON.stringify([...offlineScreenshots, payload])
    );
  };
  async function startCapture() {
    try {
      let mediaStream = await navigator.mediaDevices.getDisplayMedia(
        displayMediaOptions
      );

      // console.log(mediaStream);

      let track = mediaStream.getVideoTracks()[0];
      setTrackState(track);

      let { width, height } = track.getSettings();

      if (track.label.includes("screen")) {
        setLoading(true);

        // await getLocation();
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            let cordinates = {
              latitude: 0,
              longitude: 0,
            };
            cordinates.latitude = position.coords.latitude;
            cordinates.longitude = position.coords.longitude;
            // console.log("cordinates", position.coords.latitude, position.coords.longitude);
            // let address = await fetch(
            //   `https://api.opencagedata.com/geocode/v1/json?q=${cordinates.latitude}+${cordinates.longitude}&key=03c48dae07364cabb7f121d8c1519492&no_annotations=1&language=en`
            // );
            // address = await address.json();
            const zone = moment.tz.guess(true); //modification required
            setLoading2(true);
            const allDetails = await dispatch(
              startTask(cordinates, zone, userId)
            );
            console.log("allDetails", allDetails);
            if (allDetails) {
              setLoading2(false);
              setData(allDetails);
            }
          },
          (e) => {},
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );

        let videoELReference = document.createElement("video");
        videoELReference.srcObject = mediaStream;
        videoELReference.play();
        setVideoEl(videoELReference);
        setLoading(false);

        let interval = setInterval(() => {
          let c = document.createElement("canvas"), // create a temp. canvas
            ctx = c.getContext("2d");
          c.width = width; // set size = image, draw
          c.height = height;
          ctx.drawImage(videoELReference, 0, 0);

          var imgAsDataURL = c.toDataURL("application/octet-stream");

          const blob = dataURItoBlob(imgAsDataURL);

          let payload = {
            file: imgAsDataURL,
            date: Date.now(),
          };
          fetch("https://backend.trackkrr.com", {
            method: "GET",
            timeout: 1000,
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(() => {
              var file = new File(
                [blob],
                `${Date.now()}.jpg`,

                {
                  type: "application/octet-stream",
                }
              );
              sendOfflineScreenshots();

              let formData = new FormData();
              formData.append("screenshot", file);
              formData.append("checkinId", localStorage.getItem("checkinId"));
              if (localStorage.hasOwnProperty("checkinId")) {
                addScreenshot(formData, payload, setCapturetoLocal);
              }
            })
            .catch(() => {
              setCapturetoLocal(payload);
            });
          //   },
          //   "image/jpeg",
          //   0.75
          // );
        }, 30000);

        setIntervalState(interval);

        dispatch({
          type: "SET_SCREEN_INTERVAL",
          payload: interval,
        });
      } else {
        let stopTracks = mediaStream.getTracks();
        stopTracks.forEach((track) => {
          track.stop();
        });
        notification.error({
          message: "You must select entire screen, for sharing",
          duration: 5,
        });
      }

      // setIntervalState(interval);
    } catch (err) {
      // setLoading(true);
      // dispatch(endTask());
      // stopStreamedVideo();
      // setLoading(false);
      console.error(`Error: ${err}`);
    }
  }
  const checkOut = async () => {
    setLoading(true);
    console.log("checkOut");
    await sendOfflineScreenshots();
    const allDetails = await dispatch(endTask(false, userId));
    console.log("allDetailsC", allDetails);
    setData(allDetails);
    stopStreamedVideo();
    setLoading(false);
  };

  useEffect(() => {
    fetchCheckins();
  }, [date]);

  useEffect(() => {
    // if (localStorage.hasOwnProperty("token") && type === "employee") {
    dispatch(getUserTimelineScreenshots());
    // }
  }, []);

  useEffect(() => {
    if (type === "employee") {
      addOfflineScreenshot();

      let checkinId = localStorage.getItem("checkinId");
      if (checkinId) {
        if (!intervalState) {
          clearInterval(intervalState);
          console.log("rhis");

          dispatch(endTask(true, userId));
          startCapture();
          localStorage.removeItem("checkinId");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (intervalState && TrackState) {
      TrackState.onended = async () => {
        setLoading(true);
        clearInterval(intervalState);
        await sendOfflineScreenshots();

        dispatch(endTask(true, userId));
        setLoading(false);
      };
    }
  }, [intervalState, TrackState]);

  return (
    <Layout active="checkins">
      <div className="checkin-btn">
        {checkins?.findIndex((obj) => {
          return obj.user === userId && !obj.end;
        }) === -1 ? (
          <Button
            type="primary"
            loading={loading}
            onClick={async () => {
              startCapture();
            }}
          >
            {t("check-in")}
          </Button>
        ) : (
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              checkOut();
            }}
          >
            {t("check-out")}
          </Button>
        )}
      </div>
      <div className="checkin-head">
        <h2>{t("checkin-history")}</h2>
        <DatePicker.RangePicker
          style={{ width: "100%" }}
          onChange={onDateChange}
          disabledDate={(d) => {
            return d?.isAfter(moment());
            // return moment(d).day() === 0 || d?.isAfter(moment());
          }}
        />
      </div>
      {loading2 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <InfinityLoader />
        </div>
      ) : (
        <div className="checkin-history">
          {data ? (
            data.dates?.length > 0 ? (
              <div className="checkin-history-c1">
                {data.dates.map((date, index) => {
                  return (
                    !loading2 &&
                    !loading && (
                      <SnapComponent date={date} checkins={data?.checkins} />
                    )
                  );
                })}
              </div>
            ) : (
              <div className="empty">
                <Empty description={t("no-checkins-today")} />
              </div>
            )
          ) : (
            <div className="empty">
              <Empty description={t("no-checkins-found")} />
            </div>
          )}
          {/* {data &&
          (data?.dates?.length <= 0 ? (
            <div className="empty">
              <Empty description="No Screenshots Found" />
            </div>
          ) : (
            <div></div>
          ))} */}
        </div>
      )}
    </Layout>
  );
};

const SnapComponent = ({ date, checkins }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  const btnShow = (date) => {
    let details = [];
    let time = 0;
    let flag = false;
    let int;
    checkins.map((checkin) => {
      if (moment(checkin.date).format("YYYY-MM-DD") === date) {
        details.push(checkin);
        // setTotalHours(totalHours + checkin.hours);
        // console.log(checkin);
        // if(checkin.time.end = 'Ongoing'){
        //   flag = true
        // }
        time = time + checkin.hours;
      }
    });
    checkins.find((checkin) => {
      if (checkin.time.end === "Ongoing") {
        flag = true;
      }
    });
    // if (flag) {
    //   let interval = 1000;
    //   time = time * 60 * 60 * 1000;
    //   int = setInterval(() => {
    //     time = time + interval;
    //     // console.log(time);

    //     setTotalHours(
    //       new Date(time)
    //         .toISOString()
    //         .substr(11, 11 + 8)
    //         .split(".")[0]
    //     );
    //     if (!localStorage.getItem("checkinId")) {
    //       console.log("clear");
    //       clearInterval(int);
    //       setTotalHours(
    //         new Date(time)
    //           .toISOString()
    //           .substr(11, 11 + 8)
    //           .split(".")[0]
    //       );
    //     }
    //   }, interval);
    // } else {
      time = time * 60 * 60 * 1000;
      time = new Date(time)
        .toISOString()
        .substr(11, 11 + 8)
        .split(".")[0];

      setTotalHours(time);
    // }

    setData(details);
    setShow(!show);
  };
  return (
    <>
      <div className="screenshot-section">
        <div
          style={{
            width: "100%",
            border: "1px solid var(--borderLight",
            padding: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              width: "100%",
              marginBottom: "10px",
            }}
            onClick={() => btnShow(date)}
          >
            <span
              style={{
                color: "var(--text)",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {moment(date).tz("Asia/Karachi").format("dddd, DD-MMM-YYYY")}
            </span>
            {show ? (
              <IoIosArrowDropup
                style={{
                  color: "var(--text)",
                  fontSize: "2rem",
                  marginRight: "10px",
                }}
              />
            ) : (
              <IoIosArrowDropdown
                style={{
                  color: "var(--text)",
                  fontSize: "2rem",
                  marginRight: "10px",
                }}
              />
            )}
          </div>
          {show && (
            <>
              <Row
                gutter={[30, 15]}
                style={{
                  border: "1px solid var(--borderLight)",
                  borderRadius: "5px",
                  padding: "1rem",
                  margin: "1rem",
                  boxShadow: "var(--shadow)",
                }}
              >
                <Col span={24}>
                  <h2
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "var(--text)",
                      margin: "0",
                    }}
                  >
                    {t("checkin-details")}
                  </h2>
                  <Divider
                    style={{
                      margin: "0",
                      borderColor: "var(--text)",
                    }}
                  />
                </Col>
                <Col
                  xs={24}
                  md={24}
                  style={
                    {
                      // marginTop: "0.5rem",
                    }
                  }
                >
                  <Row>
                    <Col
                      md={11}
                      xs={24}
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AiOutlineLogin
                        style={{
                          fontSize: "2rem",
                          color: "var(--text)",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "var(--text)",
                          marginRight: "0.3rem",
                        }}
                      >
                        {t("no-of-checkins")} : {data?.length || 0}
                      </span>
                    </Col>
                    <Col
                      md={11}
                      xs={24}
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AiOutlineFieldTime
                        style={{
                          fontSize: "2rem",
                          color: "var(--text)",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "var(--text)",
                          marginRight: "0.3rem",
                        }}
                      >
                        {t("time")} :
                      </span>
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "var(--text)",
                        }}
                      >
                        {totalHours}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]} justify="space-around">
                    {data?.map((checkin, index) => {
                      return (
                        <Col xs={24} md={10} lg={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0.5rem",
                              border: "1px solid var(--borderLight)",
                              borderRadius: "5px",
                              boxShadow: "var(--shadow)",
                              minWidth: "170px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "var(--text)",
                              }}
                            >
                              {index + 1}
                            </span>
                            <span
                              style={{
                                fontSize: "0.8rem",
                                color: "var(--text)",
                              }}
                            >
                              {t("start")} : {checkin.time.start} (local)
                            </span>
                            <span
                              style={{
                                fontSize: "0.8rem",
                                color: "var(--text)",
                              }}
                            >
                              {t("end")} : {checkin.time.end} (local)
                            </span>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Checkins;
