import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "antd/dist/antd.less";
import "moment-timezone";
import "@asseinfo/react-kanban/dist/styles.css";

import "../styles/style.css";
import Routes from "./Routes";
import { light, dark } from "./theme";
import { getNotificationCount } from "../redux/actions/notifications";
import { responseTime } from "../redux";
import { useHistory } from "react-router-dom";
// import io from "socket.io-client";

const App = () => {
  // const socket = io("http://192.168.100.14:5000");
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);

  useEffect(() => {
    // socket.on("connect", () => {
    //   console.log("Connected");
    //   socket.emit("fire");
    // });
    // socket.on('disconnect', () => {
    //   console.log('disconnected')
    // })
    const type = localStorage.getItem("userType");
    type === "employee" && dispatch(getNotificationCount());
    if (theme === "light") {
      Object.keys(light).forEach((key) => {
        document.body.style.setProperty(`--${key}`, light[key]);
      });
    } else {
      Object.keys(dark).forEach((key) => {
        document.body.style.setProperty(`--${key}`, dark[key]);
      });
    }
  }, [theme]);

  let language = localStorage.getItem("language");
  console.log(language);
  return (
    <>
      <Routes />
      <div
        style={{
          background: "var(--background)",
          color: "var(--text)",
          display: "flex",
          justifyContent: "flex-end",
          padding: "1rem 2rem",
        }}
      >
        Language Preference :{" "}
        <span
          style={{
            marginLeft: "1rem",
            textDecoration: "underline",
            cursor: "pointer",
            color:
              language && language === "en" ? "var(--primary)" : "var(--text)",
          }}
          onClick={() => {
            localStorage.setItem("language", "en");
            window.location.reload();
          }}
        >
          EN
        </span>
        <span
          style={{
            marginLeft: "1rem",
            textDecoration: "underline",
            cursor: "pointer",
            color:
              language && language === "ar" ? "var(--primary)" : "var(--text)",
          }}
          onClick={() => {
            localStorage.setItem("language", "ar");
            window.location.reload();
          }}
        >
          AR
        </span>
      </div>
    </>
  );
};

export default App;
