import swal from "sweetalert";
import { notification } from "antd";

import { publicAPI, privateAPI, attachToken } from "../../config/constants";
import * as types from "../types/generalTypes";

export const getAttendanceData = async (deptFilter, desgFilter, page) => {
  try {
    let res;
    attachToken();
    res = await privateAPI.post(`/attendance/get-attendance?page=${page}`, {
      deptFilter,
      desgFilter,
    });
    if (res) {
      return res.data;
    }
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
