import {
  Select,
  Typography,
  Tree,
  Button,
  Input,
  Divider,
  Row,
  Col,
  Table,
} from "antd";
import React from "react";
import { useState } from "react";
import Layout from "../layout/Layout";
import "../styles/pages/roles.css";
import { useDispatch, useSelector } from "react-redux";
import { addRole, getRoles } from "../redux/actions/roles";
import { useEffect } from "react";
import UpdateRole from "../components/modal/UpdateRole";
import { useTranslation } from "react-i18next";
const Roles = () => {
  const { t } = useTranslation();
  const { roles } = useSelector((state) => state.roles);
  const [type, setType] = useState();

  const treeData = [
    {
      title: t('all'),
      key: "all",
      children: [
        {
          title: t('projects'),
          key: "projects",
          // children: [
          //   {
          //     title: "Add Projects",
          //     key: "add-projects",
          //   },
          //   {
          //     title: "Project Summary",
          //     key: "project-summary",
          //   },
          //   {
          //     title: "Details",
          //     key: "details",
          //     children: [
          //       {
          //         title: "View Links",
          //         key: "view-links",
          //       },
          //       {
          //         title: "Update Project",
          //         key: "update-project",
          //       },
          //       {
          //         title: "Tasks",
          //         key: "tasks",
          //         children: [
          //           {
          //             title: "Add Task",
          //             key: "add-task",
          //           },
          //           {
          //             title: "Update Task",
          //             key: "update-task",
          //           },
          //         ],
          //       },
          //     ],
          //   },
          // ],
        },
        {
          title: t('employees'),
          key: "employees",
          // children: [
          //   {
          //     title: "Add Employee",
          //     key: "add-employee",
          //   },
          //   {
          //     title: "Employee Summary",
          //     key: "employee-summary",
          //     children: [
          //       {
          //         title: "Salary Statistics",
          //         key: "salary-statistics",
          //       },
          //     ],
          //   },
          //   {
          //     title: "Update Employee",
          //     key: "update-employee",
          //   },
          // ],
        },
        {
          title: t("attendance"),
          key: "attendance",
        },
        {
          title: t("expenses"),
          key: "expenses",
          // children: [
          //   {
          //     title: "Add Expense",
          //     key: "add-expense",
          //   },
          //   {
          //     title: "Update Expense",
          //     key: "update-expense",
          //   },
          // ],
        },
        {
          title: t("reports"),
          key: "reports",
        },
        {
          title: t("moderators"),
          key: "moderators",
          // children: [
          //   {
          //     title: "Add Moderator",
          //     key: "add-moderator",
          //   },
          // ],
        },
        {
          title: t("checkins"),
          key: "checkins",
          // children: [
          //   {
          //     title: "View Checkins",
          //     key: "view-checkins",
          //   },
          //   {
          //     title: "View Snaps",
          //     key: "view-snaps",
          //   },
          // ],
        },
      ],
    },
  ];
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const dispatch = useDispatch();
  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  const assign = () => {
    dispatch(addRole({ type, roles: checkedKeys }));
    setCheckedKeys([]);
    setType("");
  };
  useEffect(() => {
    dispatch(getRoles());
  }, []);

  return (
    <Layout active={"roles"}>
      <div className="roles-container">
        <div>
          <Typography.Title
            level={3}
            style={
              {
                // marginBottom: "20px",
                // color: "#fff",
              }
            }
          >
            {t("roles")}
          </Typography.Title>
          <Typography.Title
            level={5}
            style={{
              // marginBottom: "20px",
              color: "var(--text)",
            }}
          >
            {t("create-a-new-role")}
          </Typography.Title>
        </div>
        <Row>
          <Col md={24} sm={24} xs={24}>
            <Input
              // placeholder="ex: moderator"
              value={type}
              style={{
                width: "360px",
                marginRight: "20px",
                marginBottom: "20px",
              }}
              onChange={(e) => setType(e.target.value)}
              className="roles-input"
            />
          </Col>
          <Col md={6} sm={16} xs={24}>
            <Tree
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              onSelect={onSelect}
              selectedKeys={selectedKeys}
              treeData={treeData}
              className="tree"
            />
          </Col>
          <Col md={2} sm={10} xs={24}>
            <Button
              type="primary"
              onClick={assign}
              style={{
                marginTop: "-5px",
              }}
            >
              {t('create')}
            </Button>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="roles-table-cont">
        <Table
          columns={[
            {
              title: t("type"),
              dataIndex: "type",
            },
            {
              title: t("roles"),
              dataIndex: "roles",
            },
            {
              title: t("action"),
              dataIndex: "action",
            },
          ]}
          dataSource={roles?.moderatorRoles?.map((role) => {
            return {
              type: role.type,
              roles: role.roles.join(", "),
              action: (
                <>
                  <UpdateRole data={role} treeData={treeData} />
                </>
              ),
            };
          })}
        />
      </div>
    </Layout>
  );
};

export default Roles;
