import { GET_ROLES, ADD_ROLE } from "../types/generalTypes";

const initialState = {
  roles: null,
};

const roleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ROLES:
      return {
        ...state,
        roles: payload,
      };

    default:
      return state;
  }
};

export default roleReducer;
