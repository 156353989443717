import { useState, useEffect } from "react";
import { Modal, Button, Typography, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createProject, updateProject, getEmployees } from "../../redux";
import moment from "moment";

const AddProject = ({ newProject, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="addProjectBtn"
        onClick={showModal}
        style={{ marginRight: "20px" }}
      >
        View Links
      </Button>

      <Modal
        title={data?.title}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={"600px"}
      >
        {data?.links?.map((link) => (
          <div
            style={{
              margin: "10px 0",
            }}
          >
            <a href={link} target="_blank" style={{ color: "var(--blue)" }}>
              {link}
            </a>
          </div>
        ))}
      </Modal>
    </>
  );
};

export default AddProject;
