import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { AiOutlineFundView } from "react-icons/ai";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { TbListDetails } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";

import AddProject from "../components/modal/AddProject";
import Layout from "../layout/Layout";
import { getAllProjects, getEmployeesName } from "../redux";
import InfinityLoader from "../components/InfinityLoader";
import { useHistory } from "react-router-dom";
import PDFDoghnut from "../components/Charts/PDFDoghnut";
let colors = [
  "orange",
  "red",
  "cyan",
  "green",
  "purple",
  "blue",
  "primary",
  "yellow",
];

const AdminProjects = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const projects = useSelector((state) => state.project.projects);

  let type = localStorage.getItem("userType");

  useEffect(() => {
    if (localStorage.hasOwnProperty("token") && type !== "employee") {
      dispatch(getAllProjects());
    }
  }, []);

  const randomLoop = (index) => {
    if (index >= colors.length) {
      return index % colors.length;
    } else {
      return index;
    }
  };

  const employees = useSelector((state) => state.employees.employeesName);

  useEffect(() => {
    dispatch(getEmployeesName());
  }, []);
  const moderator = JSON.parse(localStorage.getItem("moderator"));
  const roles = moderator?.roles?.roles;
  useEffect(() => {}, [roles]);
  if (localStorage.hasOwnProperty("token") && type === "employee") {
    return <Redirect to="/" />;
  } else {
    return (
      <Layout active={"admin-projects"}>
        {projects === null ? (
          <InfinityLoader />
        ) : (
          <div className="admin-project-container">
            <div className="header">
              {/* <PDFDoghnut /> */}
              <AddProject newProject={true} employees={employees}/>
            </div>
            <Row className="projects">
              {projects?.map((data, index) => (
                <Col
                  xs={24}
                  md={10}
                  lg={11}
                  xl={7}
                  key={index}
                  className={`${colors[randomLoop(index)]} box`}
                  style={{
                    minHeight: "220px",
                    maxHeight: "220px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>{data?.title}</h2>
                  <p>
                    {data?.description?.length > 0
                      ? `${data?.description?.slice(0, 50)}`
                      : "-"}
                  </p>
                  {type === "admin" ? (
                    <div className="action">
                      <h4
                        onClick={() =>
                          history.push(`/admin/project/summary/${data?._id}`)
                        }
                      >
                        <AiOutlineFundView className="icon" />
                        {t("summary")}
                      </h4>
                      <h4
                        onClick={() =>
                          history.push(`/admin/project/${data?._id}`)
                        }
                      >
                        <TbListDetails className="icon" />
                        {t("details")}
                      </h4>
                    </div>
                  ) : (
                    type === "moderator" &&
                    roles?.includes("project-summary") && (
                      <div className="action">
                        <h4
                          onClick={() =>
                            history.push(`/admin/project/summary/${data?._id}`)
                          }
                        >
                          <AiOutlineFundView className="icon" />
                          {t("summary")}
                        </h4>
                        <h4
                          onClick={() =>
                            history.push(`/admin/project/${data?._id}`)
                          }
                        >
                          <TbListDetails className="icon" />
                          {t("details")}
                        </h4>
                      </div>
                    )
                  )}
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Layout>
    );
  }
};

export default AdminProjects;
