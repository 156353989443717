import { Modal, Button, Tree } from "antd";
import React, { useState } from "react";
import { TbEdit } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { addRole } from "../../redux/actions/roles";
import '../../styles/pages/roles.css';
import { useTranslation } from "react-i18next";
const UpdateRole = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [expandedKeysE, setExpandedKeysE] = useState([]);
  const [checkedKeysE, setCheckedKeysE] = useState(props.data.roles);
  const [selectedKeysE, setSelectedKeysE] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const onExpandE = (expandedKeysValue) => {
    setExpandedKeysE(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheckE = (checkedKeysValue) => {
    setCheckedKeysE(checkedKeysValue);
  };

  const onSelectE = (selectedKeysValue, info) => {
    setSelectedKeysE(selectedKeysValue);
  };
  const onCancel = () => {
    setVisible(false);
  };
  return (
    <div>
      {!visible && (
        <Button
          className="edit-btn"
          // type="primary"
          onClick={() => {
            setVisible(true);
          }}
        >
          <TbEdit className="editBtn" />
        </Button>
      )}
      <Modal
        title={`${t('update')} ${props.data.type} ${t('role')}`}
        visible={visible}
        onCancel={onCancel}
        footer={null}
      >
        <Tree
          checkable
          onExpand={onExpandE}
          expandedKeys={expandedKeysE}
          autoExpandParent={autoExpandParent}
          onCheck={onCheckE}
          checkedKeys={checkedKeysE}
          onSelect={onSelectE}
          selectedKeys={selectedKeysE}
          treeData={props.treeData}
          className="tree"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              dispatch(addRole({ type: props.data.type, roles: checkedKeysE }));
              setVisible(false);
            }}
            style={{
              marginTop: "35px",
            }}
          >
            {t('update')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateRole;
