import Board from "@asseinfo/react-kanban";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { updateTodoStatus } from "../redux";
import { FiDelete } from "react-icons/fi";
import DeleteTodo from "./modal/DeleteTodo";
import { useTranslation } from "react-i18next";
const Todos = ({ allTodos, loading }) => {
  const { t } = useTranslation();
  const [newBoard, setBoard] = useState(null);
  const [Cards, setCards] = useState([]);
  const filter = (id) => {
    setCards(Cards.filter((card) => card.id !== id));
    console.log("filtered", id);
  };
  useEffect(() => {
    if (allTodos) {
      let todos = Object.entries(allTodos);
      let columns = [];
      todos?.map(([status, todo], ind) => {
        if (todo.length > 0) {
          let cards = [];
          todo?.map((item) => {
            cards.push({
              title: item.title,
              id: item._id,
              description: (
                <div style={{
                  position: 'relative'
                }}>
                  <p>{t('description')} : {item.description}</p>
                  {item.project && (
                    <>
                      <p>{t('assigned-to')} : </p>
                      {item.assignedTo?.map((val) => {
                        return (
                          <p
                            style={{
                              marginLeft: 80,
                            }}
                          >
                            {val.name}
                          </p>
                        );
                      })}
                    </>
                  )}
                  <DeleteTodo data={item} filter={filter} />
                </div>
              ),
            });
          });
          // setCards(cards);
          columns.push({
            id: ind,
            title: status,
            cards,
          });
        } else {
          columns.push({
            id: ind,
            title: status,
            cards: [],
          });
        }
      });
      setBoard({ columns });
    }
  }, [allTodos]);

  useEffect(() => {
    if (loading) setBoard(null);
  }, [loading]);

  return (
    <>
      {loading ? (
        <div style={{ minHeight: 500 }} />
      ) : (
        newBoard && (
          <Board
            disableColumnDrag
            onCardDragEnd={(board, Card, source, destination) => {
              let droppedTo = newBoard.columns.find((obj) => {
                return obj.id === destination.toColumnId;
              });
              updateTodoStatus({
                id: Card.id,
                status: droppedTo.title,
              });
            }}
            initialBoard={newBoard}
          />
        )
      )}
    </>
  );
};

export default Todos;
