import { Layout, Switch, Button, Row, Col, Badge } from "antd";

import { MdOutlineDarkMode, MdDarkMode } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { toggleTheme } from "../redux";
import MenuDrawer from "./MenuDrawer";
import { FiBell } from "react-icons/fi";
import {
  getNotificationCount,
  getNotifications,
} from "../redux/actions/notifications";
import { useState } from "react";
import moment from "moment";
import { BiBellPlus } from "react-icons/bi";
import { useTranslation } from "react-i18next";
const { Content } = Layout;

const MobileLayout = ({ active, children }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const history = useHistory();
  const { count } = useSelector((state) => state.notifications);
  const type = localStorage.getItem("userType");
  const [showNoti, setShowNoti] = useState(false);
  return (
    <Layout className="m-layout">
      <div className="mobile-header">
        <MenuDrawer active={active} />
        <div className="headerLft">
          {/* {type === "employee" && count?.unread > 0 && (
            <span className="notiCount">{count.unread}</span>
          )} */}
          {type === "employee" && (
            <Badge
              count={count?.unread}
              style={{
                marginRight: "30px",
              }}
            >
              <FiBell
                className="notiIcon notiBtn"
                onClick={() => {
                  setShowNoti(!showNoti);
                  dispatch(getNotifications());
                  dispatch(getNotificationCount());
                }}
                onBlur={() => {
                  setShowNoti(false);
                }}
              />
            </Badge>
          )}
          {showNoti && <NotificationComponent />}
          {type === "admin" && (
            <Badge
              count={0}
              style={{
                marginRight: "30px",
              }}
              className="notiBtn"
            >
              <BiBellPlus
                className="notiIcon notiBtn"
                onClick={() => {
                  history.push("/admin/createNotification");
                }}
              />
            </Badge>
          )}
          <Switch
            className="themeSwitch"
            defaultChecked={theme === "light" ? false : true}
            checkedChildren={
              <MdDarkMode style={{ fontSize: "20px", marginRight: "5px" }} />
            }
            unCheckedChildren={
              <MdOutlineDarkMode
                style={{ fontSize: "20px", marginLeft: "5px" }}
              />
            }
            onChange={() => dispatch(toggleTheme())}
          />
        </div>
      </div>
      <Content className="m-children">{children}</Content>
    </Layout>
  );
};
const NotificationComponent = () => {
  const { t } = useTranslation();
  const { notifications } = useSelector((state) => state.notifications);
  const history = useHistory();
  return (
    <div className="notiComp">
      <Row>
        <Col span={24}>
          <div className="notiHeader">
            <span>{t('notifications')}</span>
          </div>
        </Col>
        <div
          style={{
            minHeight: "310px",
            width: "100%",
          }}
        >
          {notifications?.notifications?.length > 0 ||
          notifications?.gNotifications?.length > 0 ? (
            <>
              {notifications?.gNotifications?.length > 0 ? (
                notifications.gNotifications.map((noti, ind) => {
                  return (
                    <Col
                      span={24}
                      className="notiCard"
                      key={ind}
                      style={{
                        height: "auto",
                        padding: "20px 0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span className="message">{noti.message}</span>
                        <span className="date">
                          {moment(noti.date).local().format("DD/MM HH:mm a")}
                        </span>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <div></div>
              )}
              {notifications?.notifications?.length > 0 ? (
                notifications.notifications.map((noti, ind) => {
                  return (
                    <Col span={24} className="notiCard" key={ind}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span className="message">{noti.message}</span>
                        <span className="date">
                          {moment(noti.date).local().format("DD/MM HH:mm a")}
                        </span>
                      </div>
                      {noti.type.type === "project" ||
                      noti.type.type === "todo" ? (
                        <Button
                          type="primary"
                          onClick={() => {
                            history.push(`/project/${noti.type.id}`);
                          }}
                          ant-click-animating-without-extra-node="false"
                        >
                          {t('view')}
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                  );
                })
              ) : (
                <div>{/* <span>No new specefic Notification</span> */}</div>
              )}
            </>
          ) : (
            <div>{t('no-new-notifications')}</div>
          )}

          {/* <Col span={24} className="notiCard">
            <span className="message">
              You are assigned a project by admin. Start working asap
            </span>
            <Button type='primary'>Project</Button>
          </Col>
          <Col span={24} className="notiCard">
            <span className="message">
              You are assigned a project by admin. Start working asap
            </span>
            <Button type='primary'>Project</Button>
          </Col>
          <Col span={24} className="notiCard">
            <span className="message">
              You are assigned a project by admin. Start working asap
            </span>
            <Button type='primary'>Project</Button>
          </Col>
          <Col span={24} className="notiCard">
            <span className="message">
              You are assigned a project by admin. Start working asap
            </span>
            <Button type='primary'>Project</Button>
          </Col> */}
        </div>
        <Col span={24}>
          <div className="notiFooter">
            <Link
              to={"/notifications"}
              style={{
                color: "var(--primary)",
              }}
            >
              {t('show-all-notifications')}
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MobileLayout;
