import { useState } from "react";
import { Modal, Button, Form, Input, Row, Col, DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { createRemarks, updateRemarks } from "../../redux";
import { MdOutlineFileUpload, MdEdit } from "react-icons/md";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AddRemarks = ({ id, state, getData }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [DateSelect, setDateSelect] = useState(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    let payload = {
      ...values,
      hours: Number(values.hours),
      project: id,
      CreatedAt: values.CreatedAt ? values.CreatedAt : moment().toISOString(),
    };
    let updatePayload = {
      ...payload,
      timelineId: state?._id,
      hourlySalary: state?.user?.hourlySalary,
    };
    setLoading(true);
    const res = state
      ? await dispatch(updateRemarks(updatePayload))
      : await dispatch(createRemarks(payload));
    if (res) {
      setLoading(false);
      setIsModalVisible(false);
      form.resetFields();
      state && getData();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onDateFilter = (value, dateString) => {
    // setDateSelect(moment().set("date", dateString).toISOString());
  };

  return (
    <>
      {!state ? (
        <h4 onClick={showModal}>
          <MdOutlineFileUpload className="icon" /> {t("add-timeline")}
        </h4>
      ) : (
        <MdEdit className="edit icon" onClick={showModal} />
      )}
      <Modal
        title={t("add-remarks")}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={"600px"}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          fields={
            state
              ? [
                  {
                    name: "hours",
                    value: state?.hours,
                  },
                  {
                    name: "remarks",
                    value: state?.remarks,
                  },
                ]
              : null
          }
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item label={t("date")} name={"CreatedAt"}>
                {state ? (
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment(state?.CreatedAt).local()}
                    disabled
                  />
                ) : (
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder={t("select-date")}
                    onChange={onDateFilter}
                    disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t("hours")}
                name={"hours"}
                rules={[
                  {
                    required: true,
                    message: t("hours-required"),
                  },
                ]}
                requiredMark={"optional"}
              >
                <Input type={"number"} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label={t("remarks")} name={"remarks"}>
                <Input.TextArea rows={4} style={{ resize: "none" }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType="submit" loading={loading} type="primary">
              {state ? t("update-remarks") : t("add-remarks")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddRemarks;
