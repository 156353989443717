import { Button, Col, DatePicker, notification, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ColumnChart from "../components/Charts/ColumnChart";
import LineChart from "../components/Charts/LineChart";
import InfinityLoader from "../components/InfinityLoader";
import EmployeeCostingPdf from "../components/PdfTemplate/EmployeeCostingPdf";
import Layout from "../layout/Layout";
import {
  getEmployeeDailyCosting,
  getEmployeeCosting,
  getEmployeeDailyCostingOwnAdmin,
  getEmployeeSalaries,
} from "../redux";
import { useTranslation } from "react-i18next";
const EmployeeSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [DailyData, setDailyData] = useState(null);
  const [costingData, setcostingData] = useState(null);
  const [salaries, setSalaries] = useState([]);
  const [selectedYear, setselectedYear] = useState(moment().format("YYYY"));
  const [SelectedMonth, setSelectedMonth] = useState(moment().format("MMMM"));
  const [pdfMonth, setpdfMonth] = useState(null);
  const [tableData, settableData] = useState(null);
  const [pdfLoader, setpdfLoader] = useState(false);

  const GetData = async () => {
    const dailyResponse = await dispatch(getEmployeeDailyCosting(id));
    let dailyResponse2 = dailyResponse?.costing?.map((data) => {
      return {
        ...data,
        day: String(data.day),
      };
    });
    setDailyData({
      ...dailyResponse,
      costing: dailyResponse2,
    });

    const costResponse = await dispatch(getEmployeeCosting(id));
    setcostingData(costResponse);

    const salary = await dispatch(getEmployeeSalaries(id));
    setSalaries(salary);
  };

  useEffect(() => {
    GetData();
  }, []);

  const OnDailyDateChange = async (value, dateString) => {
    setSelectedMonth(moment(value).format("MMMM"));
    setpdfMonth(moment(value).format("MM"));
    const dailyResponse = await dispatch(
      getEmployeeDailyCosting(id, { month: moment(value).format("MM") })
    );
    let dailyResponse2 = dailyResponse?.costing?.map((data) => {
      return {
        ...data,
        day: String(data.day),
      };
    });
    setDailyData({
      ...dailyResponse,
      costing: dailyResponse2,
    });
  };

  const OnCostingDateChange = async (value, dateString) => {
    setselectedYear(moment(value).format("YYYY"));
    const costResponse = await dispatch(
      getEmployeeCosting(id, { year: moment(value).format("YYYY") })
    );
    setcostingData(costResponse);
  };

  useEffect(() => {
    let data = [];
    costingData?.costing?.map((i) => {
      data.push({
        month: i.month,
        hours: i.hours,
        cost: "Rs. " + i.cost,
      });
    });
    settableData(data);
  }, [costingData]);

  const columns = [
    {
      title: t("month"),
      dataIndex: "month",
    },
    {
      title: t("hours"),
      dataIndex: "hours",
    },
    {
      title: t("cost"),
      dataIndex: "cost",
    },
  ];

  const getPdfGenerate = async () => {
    setpdfLoader(true);
    const dailyResponse = await dispatch(
      getEmployeeDailyCostingOwnAdmin(id, { month: pdfMonth })
    );
    if (dailyResponse.costing.length !== 0) {
      EmployeeCostingPdf(
        dailyResponse?.costing,
        SelectedMonth,
        dailyResponse?.employee,
        dailyResponse?.totalHours,
        dailyResponse?.totalSalary,
        `${t("employee-costing-report")}.pdf`,
        dailyResponse?.expensePerEmployee,
        dailyResponse?.projectWiseData
      );
      setpdfLoader(false);
    } else {
      notification.error({
        description: t("no-entries-found"),
        duration: 2,
      });
      setpdfLoader(false);
    }
  };
  const moderator = JSON.parse(localStorage.getItem("moderator"));
  const type = localStorage.getItem("userType");
  const roles = moderator?.roles?.roles;
  useEffect(() => {}, [roles]);
  return (
    <Layout active={"admin-employees"}>
      {DailyData === null ? (
        <InfinityLoader />
      ) : type !== "moderator" ? (
        <div className="summary-container">
          <br />
          <Row gutter={[20, 40]} justify={"center"}>
            <Col xs={24} md={12} xl={8}>
              <h2 className="label">
                {t("name")}: <span>{DailyData?.employee?.name}</span>{" "}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className="label">
                {t("email")}: <span>{DailyData?.employee?.email}</span>{" "}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className="label">
                {t("phone")}: <span>{DailyData?.employee?.phone}</span>{" "}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className="label">
                {t("no-of-hours-per-day")}:{" "}
                <span>{DailyData?.employee?.noOfHours}</span>{" "}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className="label">
                {t("days-per-month")} :{" "}
                <span>{DailyData?.employee?.daysPerMonth}</span>{" "}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className="label">
                {t("designation")} :{" "}
                <span>{DailyData?.employee?.designation}</span>{" "}
              </h2>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs={24}>
              <div className="header">
                <h3 className="summaryHeading">
                  {SelectedMonth} {t("day-wise-daily-hours")}
                </h3>
                <div>
                  {DailyData && (
                    <Button
                      type="primary"
                      style={{ marginRight: "1rem" }}
                      onClick={() => getPdfGenerate()}
                      loading={pdfLoader}
                    >
                      {t("download-pdf")}
                    </Button>
                  )}
                  <DatePicker
                    placeholder={t("select-month")}
                    onChange={OnDailyDateChange}
                    style={{ width: "200px" }}
                    picker={"month"}
                    disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>

              <div className="card">
                <LineChart
                  data={DailyData?.costing ? DailyData?.costing : []}
                  yField="dailyHours"
                  xField="day"
                />
              </div>
            </Col>
            <Col xs={24}>
              <div className="header header2">
                <h3 className="summaryHeading ">
                  {t("year")} {selectedYear} {t("monthly-hours")}
                </h3>
                <DatePicker
                  placeholder={t("select-year")}
                  onChange={OnCostingDateChange}
                  picker="year"
                  style={{ width: "200px" }}
                  disabledDate={(d) => {
                    return d?.isAfter(moment());
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                />
              </div>
              <div className="card card2">
                <ColumnChart
                  data={costingData?.costing ? costingData?.costing : []}
                  yField="hours"
                  xField="month"
                  alias
                />
              </div>
            </Col>
            <Col xs={24}>
              <br />
              <Table
                sticky
                dataSource={tableData}
                className="project-table"
                columns={columns}
                pagination={false}
              />
            </Col>
            <Col xs={24}>
              <div
                className="header"
                style={{
                  marginTop: "4rem",
                }}
              >
                <h3 className="summaryHeading">
                  {t("employee-salary-statistics")}
                </h3>
              </div>
              <div className="card">
                <LineChart
                  data={salaries ? salaries : []}
                  yField="salary"
                  xField="CreatedAt"
                />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        roles?.includes("employee-summary") !== false && (
          <div className="summary-container">
            <br />
            <Row gutter={[20, 40]} justify={"center"}>
              <Col xs={24} md={12} xl={8}>
                <h2 className="label">
                  {t("name")}: <span>{DailyData?.employee?.name}</span>{" "}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className="label">
                  {t("email")}: <span>{DailyData?.employee?.email}</span>{" "}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className="label">
                  {t("phone")}: <span>{DailyData?.employee?.phone}</span>{" "}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className="label">
                  {t("no-of-hours-per-day")}:{" "}
                  <span>{DailyData?.employee?.noOfHours}</span>{" "}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className="label">
                  {t("days-per-month")} :{" "}
                  <span>{DailyData?.employee?.daysPerMonth}</span>{" "}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className="label">
                  {t("designation")} :{" "}
                  <span>{DailyData?.employee?.designation}</span>{" "}
                </h2>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs={24}>
                <div className="header">
                  <h3 className="summaryHeading">
                    {SelectedMonth} {t("day-wise-daily-hours")}
                  </h3>
                  <div>
                    {DailyData && (
                      <Button
                        type="primary"
                        style={{ marginRight: "1rem" }}
                        onClick={() => getPdfGenerate()}
                        loading={pdfLoader}
                      >
                        {t("download-pdf")}
                      </Button>
                    )}
                    <DatePicker
                      placeholder={t("select-month")}
                      onChange={OnDailyDateChange}
                      style={{ width: "200px" }}
                      picker={"month"}
                      disabledDate={(d) => {
                        return d?.isAfter(moment());
                        // return moment(d).day() === 0 || d?.isAfter(moment());
                      }}
                    />
                  </div>
                </div>

                <div className="card">
                  <LineChart
                    data={DailyData?.costing ? DailyData?.costing : []}
                    yField="dailyHours"
                    xField="day"
                  />
                </div>
              </Col>
              <Col xs={24}>
                <div className="header header2">
                  <h3 className="summaryHeading ">
                    {t("year")} {selectedYear} {t("monhtly-hours")}
                  </h3>
                  <DatePicker
                    placeholder={t("select-year")}
                    onChange={OnCostingDateChange}
                    picker="year"
                    style={{ width: "200px" }}
                    disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
                <div className="card card2">
                  <ColumnChart
                    data={costingData?.costing ? costingData?.costing : []}
                    yField="hours"
                    xField="month"
                    alias
                  />
                </div>
              </Col>
              <Col xs={24}>
                <br />
                <Table
                  sticky
                  dataSource={tableData}
                  className="project-table"
                  columns={columns}
                  pagination={false}
                />
              </Col>
              {roles?.includes("salary-statistics") !== false && (
                <Col xs={24}>
                  <div
                    className="header"
                    style={{
                      marginTop: "4rem",
                    }}
                  >
                    <h3 className="summaryHeading">
                      {t("employee-salary-statistics")}
                    </h3>
                  </div>
                  <div className="card">
                    <LineChart
                      data={salaries ? salaries : []}
                      yField="salary"
                      xField="CreatedAt"
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        )
      )}
    </Layout>
  );
};

export default EmployeeSummary;
