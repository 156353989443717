import { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import { Link, useHistory, Redirect, NavLink } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

import { authLogin } from "../redux";
import Header from "../components/Header";
import logo from "../assets/Logo.png";

const SignIn = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  let type = localStorage.getItem("userType");
  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(authLogin(values, history));
    setLoading(false);
  };

  if (localStorage.hasOwnProperty("token") && type === "employee") {
    return <Redirect to="/employee/checkins" />;
  } else if (
    localStorage.hasOwnProperty("token") &&
    (type === "admin" || type === "moderator")
  ) {
    return <Redirect to="/admin/dashboard" />;
  } else {
    return (
      <>
        <div className="signin">
          <Form
            name="login"
            className="login-form"
            layout="vertical"
            onFinish={onFinish}
          >
            {/* <img src={logo} / > */}
            <img
              src={logo}
              style={{
                width: 200,
                // marginRight: 20,
              }}
            />
            <h2>{t("sign-in")}</h2>
            {/* <div style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 20,
            }}>
              <div style={{
                width: "76%",
              }}>
              </div>
            </div> */}
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("email-invalid"),
                },
                {
                  required: true,
                  message: t("email-required"),
                },
              ]}
              label={t("email")}
            >
              <Input autoComplete="off" placeholder={t("email")} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("password-required"),
                },
              ]}
              label={t("password")}
            >
              {/* <Input.Password */}
              <Input type="password" placeholder={t("password")} />
            </Form.Item>

            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {t("login")}
              </Button>
            </Form.Item>
            <Form.Item>
              <NavLink
                to="/forgotPassword"
                style={{
                  float: "right",
                  textDecoration: "underline",
                }}
              >
                {t("forgot-password")}
              </NavLink>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  }
};

export default SignIn;
