import { Typography, Input, Row, Col, Button, notification } from "antd";
import InfinityLoader from "../components/InfinityLoader";
import TodoComp from "../components/TodoComp";
import Layout from "../layout/Layout";
import { useEffect, useState } from "react";
import { addTodo, getUserTodos, updateTodoStatus } from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const Todos = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [desc, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const allTodos = useSelector((state) => state.todo.userTodos);

  useEffect(() => {
    (async () => {
      await dispatch(getUserTodos());
    })();
  }, []);

  const addTodoItem = async () => {
    if (title && desc) {
      setLoading(true);
      let payload = {
        title,
        description: desc,
      };
      await dispatch(addTodo(payload));
      setLoading(false);
    } else {
      notification.warning({
        message: "Please add title and description both",
        duration: 5,
      });
    }
  };

  return (
    <Layout active={"Todos"}>
      {allTodos === null ? (
        <InfinityLoader />
      ) : (
        <div className="todo-main">
          <Typography.Title level={4}>{t('employee-todos')}</Typography.Title>

          <div className="todo-input">
            <Row gutter={[20, 20]}>
              <Col xs={24} md={6}>
                <Input
                  placeholder={t('add-title')}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Col>

              <Col xs={24} md={12}>
                <Input
                  placeholder={t('write-note')}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Col>

              <Col xs={24} md={6}>
                <Button type="primary" loading={loading} onClick={addTodoItem}>
                  {t('add-todo')}
                </Button>
              </Col>
            </Row>
          </div>
          <TodoComp allTodos={allTodos} loading={loading} />
        </div>
      )}
    </Layout>
  );
};

export default Todos;
