export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_ALL_MODERATORS = "GET_ALL_MODERATORS";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const GET_CHECKINS = "GET_CHECKINS";
export const GET_ALL_TODOS = "GET_ALL_TODOS";
export const GET_PROJECT_TODOS = "GET_PROJECT_TODOS";

export const GET_ADMIN_EXPENSE = "GET_ADMIN_EXPENSE";

export const GET_ROLES = "GET_ROLES";
export const ADD_ROLE = "ADD_ROLE";

export const GET_LOGS = "GET_LOGS";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTICOUNT = "GET_NOTICOUNT";
export const GET_ALLNOTIFICATIONS = "GET_ALLNOTIFICATIONS";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";

export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";

export const BLOCK_EMPLOYEE = "BLOCK_EMPLOYEE";
export const UNBLOCK_EMPLOYEE = "UNBLOCK_EMPLOYEE";

export const GET_ALL_EMPLOYEES_NAME = "GET_ALL_EMPLOYEES_NAME";

export const GET_ALL_EMPLOYEES_BY_PROJECT = "GET_ALL_EMPLOYEES_BY_PROJECT";
export const GET_ALL_PROJECTS_BY_EMPLOYEE = "GET_ALL_PROJECTS_BY_EMPLOYEE";

export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DESIGNATIONS = "GET_DESIGNATIONS";
export const MODERATOR_LOGIN = "MODERATOR_LOGIN";
