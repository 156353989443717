import { useState, useEffect } from "react";
import { Col, Row, Button, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { TbListDetails } from "react-icons/tb";
import Layout from "../layout/Layout";
import {
  getAllEmployeeProjects,
  getUserTimelineScreenshots,
  startTask,
  endTask,
  addScreenshot,
  addOfflineScreenshot,
} from "../redux";
import InfinityLoader from "../components/InfinityLoader";
import { useHistory } from "react-router-dom";
import AddRemarks from "../components/modal/AddRemarks";
import moment from "moment";
import dataURItoBlob from "../service/dataURItoBlob";
import {useTranslation} from "react-i18next";
let colors = [
  "orange",
  "red",
  "cyan",
  "green",
  "purple",
  "blue",
  "primary",
  "yellow",
];

const UserProject = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const projects = useSelector((state) => state.project.projects);
  // const checkins = useSelector((state) => state.project.checkins);
  const [loading, setLoading] = useState(false);
  const [isOnline, setNetwork] = useState(navigator.onLine);

  let type = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (localStorage.hasOwnProperty("token") && type === "employee") {
      dispatch(getAllEmployeeProjects());
      // dispatch(getUserTimelineScreenshots());
    }
  }, []);

  // useEffect(() => {
  //   window.addEventListener("offline", () => setNetwork(navigator.onLine));
  //   window.addEventListener("online", () => setNetwork(navigator.onLine));
  // }, [isOnline]);

  // useEffect(() => {
  //   if (type === "employee") {
  //     addOfflineScreenshot();
  //     let checkinId = localStorage.getItem("checkinId");
  //     if (checkinId) {
  //       if (!intervalState) {
  //         clearInterval(intervalState);
  //         dispatch(endTask(true));
  //         startCapture();
  //         localStorage.removeItem("checkinId");
  //       }
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (intervalState && TrackState) {
  //     TrackState.onended = async () => {
  //       setLoading(true);
  //       clearInterval(intervalState);
  //       await sendOfflineScreenshots();
  //       dispatch(endTask(true));
  //       setLoading(false);
  //     };
  //   }
  // }, [intervalState, TrackState]);

  // const sendOfflineScreenshots = () => {
  //   let offlineScreenshots = localStorage.getItem("offlineScreenshots")
  //     ? JSON.parse(localStorage.getItem("offlineScreenshots"))
  //     : [];

  //   if (offlineScreenshots.length > 0) {
  //     // .format("YYYY-MM-DD--HH-MM")

  //     let formData = new FormData();
  //     offlineScreenshots?.map((data) => {
  //       const blob = dataURItoBlob(data.file);

  //       var file = new File([blob], `${data.date}.jpg`, {
  //         type: "application/octet-stream",
  //       });
  //       formData.append("screenshot", file);
  //     });
  //     formData.append("checkinId", localStorage.getItem("checkinId"));
  //     addOfflineScreenshot(formData);
  //   }
  // };

  const randomLoop = (index) => {
    if (index >= colors.length) {
      return index % colors.length;
    } else {
      return index;
    }
  };

  // const displayMediaOptions = {
  //   video: {
  //     cursor: "always",
  //   },
  //   audio: false,
  // };

  // function stopStreamedVideo() {
  //   // let videoEL = document.getElementById("video");
  //   // console.log(videoEL);
  //   if (videoEL) {
  //     const streamVar = videoEL.srcObject;
  //     if (streamVar) {
  //       const tracks = streamVar.getTracks();

  //       tracks.forEach((track) => {
  //         track.stop();
  //       });
  //     }
  //     clearInterval(intervalState);
  //   }
  //   // console.log("clear interval");
  //   // videoEL.srcObject = null;
  // }

  // TODO
  // image upload in local if internet is not available
  // bulk image uploads
  // if users refreshes the tab
  // upload images stored in local
  // try to start screenshare pop up
  // end the current task

  // const setCapturetoLocal = (payload) => {
  //   let offlineScreenshots = localStorage.getItem("offlineScreenshots")
  //     ? JSON.parse(localStorage.getItem("offlineScreenshots"))
  //     : [];

  //   // console.log("offlineScreenshots", offlineScreenshots);

  //   localStorage.setItem(
  //     "offlineScreenshots",
  //     JSON.stringify([...offlineScreenshots, payload])
  //   );
  // };

  // let pAddress;
  // const getLocation = async () => {
  //   // let ip = await fetch("https://api.ipify.org/?format=json");
  //   // ip = await ip.json();
  //   // let location = await fetch(`https://ipapi.co/${ip.ip}/json`);
  //   // location = await location.json();
  //   // let address = await fetch(
  //   //   `https://api.opencagedata.com/geocode/v1/json?q=${location.latitude}+${location.longitude}&key=03c48dae07364cabb7f121d8c1519492&no_annotations=1&language=en`
  //   // );
  //   // address = await address.json();
  //   // console.log(location)
  //   // cordinates.latitude = location.latitude;
  //   // cordinates.longitude = location.longitude;
  //   // pAddress = address.results[0].formatted;
  // };
  // async function startCapture() {
  //   try {
  //     let mediaStream = await navigator.mediaDevices.getDisplayMedia(
  //       displayMediaOptions
  //     );

  //     // console.log(mediaStream);

  //     let track = mediaStream.getVideoTracks()[0];
  //     setTrackState(track);

  //     let { width, height } = track.getSettings();

  //     if (track.label.includes("screen")) {
  //       setLoading(true);

  //       // await getLocation();
  //       navigator.geolocation.getCurrentPosition(
  //         async (position) => {
  //           let cordinates = {
  //             latitude: 0,
  //             longitude: 0,
  //           };
  //           cordinates.latitude = position.coords.latitude;
  //           cordinates.longitude = position.coords.longitude;
  //           // console.log("cordinates", position.coords.latitude, position.coords.longitude);
  //           // let address = await fetch(
  //           //   `https://api.opencagedata.com/geocode/v1/json?q=${cordinates.latitude}+${cordinates.longitude}&key=03c48dae07364cabb7f121d8c1519492&no_annotations=1&language=en`
  //           // );
  //           // address = await address.json();
  //           dispatch(startTask(cordinates));
  //         },
  //         (e) => {},
  //         {
  //           enableHighAccuracy: true,
  //           timeout: 5000,
  //           maximumAge: 0,
  //         }
  //       );

  //       let videoELReference = document.createElement("video");
  //       videoELReference.srcObject = mediaStream;
  //       videoELReference.play();
  //       setVideoEl(videoELReference);
  //       setLoading(false);

  //       let interval = setInterval(() => {
  //         let c = document.createElement("canvas"), // create a temp. canvas
  //           ctx = c.getContext("2d");
  //         c.width = width; // set size = image, draw
  //         c.height = height;
  //         ctx.drawImage(videoELReference, 0, 0);

  //         var imgAsDataURL = c.toDataURL("application/octet-stream");

  //         const blob = dataURItoBlob(imgAsDataURL);

  //         let payload = {
  //           file: imgAsDataURL,
  //           date: Date.now(),
  //         };
  //         fetch("https://backend.trackkrr.com", {
  //           method: "GET",
  //           timeout: 1000,
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         })
  //           .then(() => {
  //             var file = new File(
  //               [blob],
  //               `${Date.now()}.jpg`,

  //               {
  //                 type: "application/octet-stream",
  //               }
  //             );
  //             sendOfflineScreenshots();

  //             let formData = new FormData();
  //             formData.append("screenshot", file);
  //             formData.append("checkinId", localStorage.getItem("checkinId"));
  //             if (localStorage.hasOwnProperty("checkinId")) {
  //               addScreenshot(formData, payload, setCapturetoLocal);
  //             }
  //           })
  //           .catch(() => {
  //             setCapturetoLocal(payload);
  //           });
  //         //   },
  //         //   "image/jpeg",
  //         //   0.75
  //         // );
  //       }, 30000);

  //       setIntervalState(interval);

  //       dispatch({
  //         type: "SET_SCREEN_INTERVAL",
  //         payload: interval,
  //       });
  //     } else {
  //       let stopTracks = mediaStream.getTracks();
  //       stopTracks.forEach((track) => {
  //         track.stop();
  //       });
  //       notification.error({
  //         message: "You must select entire screen, for sharing",
  //         duration: 5,
  //       });
  //     }

  //     // setIntervalState(interval);
  //   } catch (err) {
  //     // setLoading(true);
  //     // dispatch(endTask());
  //     // stopStreamedVideo();
  //     // setLoading(false);
  //     console.error(`Error: ${err}`);
  //   }
  // }
  // const checkOut = async () => {
  //   setLoading(true);
  //   console.log("checkOut");
  //   await sendOfflineScreenshots();
  //   dispatch(endTask(false));
  //   stopStreamedVideo();
  //   setLoading(false);
  // };
  // useEffect(() => {
  //   // checkOut();
  // }, []);

  if (localStorage.hasOwnProperty("token") && type !== "employee") {
    return <Redirect to="/admin/projects" />;
  } else {
    return (
      <Layout active={"admin-projects"}>
        {projects === null ? (
          <InfinityLoader />
        ) : (
          <div className="admin-project-container">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              {/* {checkins?.findIndex((obj) => {
                return obj.user === userId && !obj.end;
              }) === -1 ? (
                <Button
                  type="primary"
                  loading={loading}
                  onClick={async () => {
                    startCapture();
                  }}
                >
                  Check In
                </Button>
              ) : (
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    checkOut();
                  }}
                >
                  Check Out
                </Button>
              )} */}
            </div>

            <Row className="projects">
              {projects?.map((data, index) => (
                <Col
                  xs={24}
                  md={10}
                  lg={11}
                  xl={7}
                  key={index}
                  className={`${colors[randomLoop(index)]} box`}
                  style={{
                    minHeight: "220px",
                    maxHeight: "220px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>{data?.title}</h2>
                  <p>
                    {" "}
                    {data?.description?.length > 0
                      ? `${data?.description?.slice(0, 50)}`
                      : "-"}
                  </p>
                  <div className="action">
                    <h4 onClick={() => history.push(`/project-e/${data?._id}`)}>
                      <TbListDetails className="icon" />
                      {t('details')}
                    </h4>
                    <AddRemarks id={data?._id} />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Layout>
    );
  }
};

export default UserProject;
