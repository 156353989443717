import { combineReducers } from "redux";

import employees from "./reducers/employees";
import project from "./reducers/project";
import theme from "./reducers/themeReducer";
import moderator from "./reducers/moderators";
import todo from "./reducers/todo";
import roles from "./reducers/roles";
import admin from "./reducers/admin";
import logs from "./reducers/logs";
import notifications from "./reducers/notifocation";
import report from "./reducers/report";
import departments from './reducers/departments'
const rootReducer = combineReducers({
  employees,
  project,
  theme,
  moderator,
  todo,
  admin,
  roles,
  logs,
  notifications,
  report,
  departments,
});

export default rootReducer;
