import React from "react";
import moment from "moment";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
const LogsCard = (props) => {
  const { t } = useTranslation();
  let date = moment(props.log.date).local().format("D-MM-YYYY");
  let time = moment(props.log.date).local().format("hh:mm a");
  return (
    <Row className="logs-card">
      <Col span={16} className="logs-top">
        {/* <span>Type : {props.log.type}</span> */}
        {/* moment(new Date(req.body.date)).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") */}
        <span>
          {t('date')} : {date} {time}
        </span>
        {/* <span>Date : {moment(props.log.date).format("DD/MM/YYYY HH:MM a")}</span> */}
      </Col>
      <Col span={24} className="logs-message">
        <span>
          {t('message')} : {props.log.message}{" "}
          {props.log.admin?.name || props.log.user?.name || "undefined"}{" "}
          {props.user?.name}
        </span>
      </Col>
    </Row>
  );
};

export default LogsCard;
