import React from "react";
import Layout from "../layout/Layout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDeletedTodos, restoreTodo } from "../redux";
import { useState } from "react";
import "../styles/pages/DeletedTodos.css";
import { Table } from "antd";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
const DeletedTodos = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [todos, setTodos] = useState();
  const dispatch = useDispatch();
  useEffect(async () => {
    const data = await dispatch(getDeletedTodos(id));
    setTodos(data);
  }, []);

  return (
    <Layout active={"admin-projects"}>
      {console.log(todos)}
      <div className="deleted-todo-pg">
        <div className="deleted-todo-header">
          <h1>{t('deleted-todos')}</h1>
        </div>
        <div className="deleted-todo-body">
          <div className="deleted-todo-table">
            <Table
              columns={[
                {
                  title: t('title'),
                  dataIndex: "title",
                  key: "title",
                },
                {
                  title: t('description'),
                  dataIndex: "description",
                  key: "description",
                },
                {
                  title: t('deleted-reason'),
                  dataIndex: "reason",
                  key: "reason",
                },
                {
                  title: t('deleted-by'),
                  dataIndex: "deletedBy",
                  key: "deletedBy",
                },
                {
                  title: t('restore'),
                  dataIndex: "restore",
                  key: "restore",
                },
              ]}
              dataSource={todos?.map((todo) => {
                return {
                  title: todo.todo.title,
                  description: todo.todo.description,
                  reason: todo.reason,
                  deletedBy: todo.user ? todo.user.name : todo.admin.name,
                  restore: (
                    <RiArrowGoBackFill
                      style={{
                        fontSize: "1.5rem",
                        color: "var(--text)",
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        const res = await dispatch(
                          restoreTodo(todo.todo._id, id)
                        );
                        if (res) {
                          console.log(res)
                          setTodos(res);
                        }
                      }}
                    />
                  ),
                };
              })}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeletedTodos;
