import { useState, useEffect } from "react";
import {
  DatePicker,
  Divider,
  Select,
  Typography,
  Form,
  Row,
  Col,
  Input,
  Button,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../layout/Layout";
import {
  getEmployeesName,
  getAllProjects,
  getByResource,
  getReportsAllProjects,
  getReportsAllEmployees,
  getExpenses,
} from "../redux";
import InfinityLoader from "../components/InfinityLoader";
import { MdOutlineArrowBack } from "react-icons/md";
import ProjectDetailTable from "../components/view/ProjectDetailTable";
import ProjectCostingPdf from "../components/PdfTemplate/ProjectCostingPdf";
import EmployeeCostingPdf from "../components/PdfTemplate/EmployeeCostingPdf";
import {
  getEmployeesByProject,
  getProjectsByEmployee,
} from "../redux/actions/report";

const ProjectDetail = () => {
  const dispatch = useDispatch();
  const [detail, setDetail] = useState("hi");
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const employees = useSelector((state) => state.employees.employeesName);
  const projects = useSelector((state) => state.project.projects);
  const projEmployees = useSelector((state) => state.report.employees);
  const empProjects = useSelector((state) => state.report.projects);
  // const admin = useSelector((state) => state.admin);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [firstTable, setFirstTable] = useState(null);
  const [secondTable, setSecondTable] = useState(null);
  const [thirdTable, setThirdTable] = useState(null);
  const [firstTableCondition, setFirstTableCondition] = useState();
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [firstStartDate, setFirstStartDate] = useState(null);
  const [firstEndDate, setFirstEndDate] = useState(null);
  useEffect(() => {
    dispatch(getEmployeesName());
    dispatch(getAllProjects());
    // dispatch(getExpenses());
  }, []);

  const onFinish = async (values) => {
    if (values.project === "default" && values.user === "default") {
      return notification.error({
        message: "Select atleast one resource...",
        duration: 5,
      });
    }
    if (values.project || values.user) {
      setLoader1(true);
      // let start;
      // let end;
      let range;
      if (values.date) {
        // start = moment(values.date[0]?._d).format("YYYY-MM-DD");
        // end = moment(values.date[1]?._d).format("YYYY-MM-DD");
        if (values.date === "currentMonth") {
          range = "Current Month";
        } else if (values.date === "lastMonth") {
          range = "Last Month";
        } else if (values.date === "last3Months") {
          range = "Last 3 Months";
        } else if (values.date === "last6Months") {
          range = "Last 6 Months";
        }
        // setFirstStartDate(start);
        // setFirstEndDate(end);
      }
      let payload = {
        project: values.project,
        user: values.user,
        // start: values.date ? start : null,
        // end: values.date ? end : null,
        date: values.date || "currentMonth",
      };
      const result = await getByResource(payload);
      console.log(result);
      if (values.user || values.project) {
        setSelectedProjectName(values.project);
        if (result?.data?.timeline?.length > 0 || result?.data?.empcount > 0) {
          // setFirstTable(result);
          if (values.user) {
            // setFirstTableCondition("employee");
            EmployeeCostingPdf(
              result?.data?.timeline,
              range ? range : "Current Month",
              // start
              //   ? `${start} ${t('till')} ${end}`
              //   : values.project
              //   ? result?.timeline?.[0]?.project?.title
              //   : "",
              result?.data?.timeline?.[0]?.user || result?.data?.employee,
              result?.data?.timelineHours,
              Number(result?.data?.totalCost)?.toFixed(0),
              `${t("employee-summary-report")}.pdf`,
              result?.data?.expensePerEmployee,
              result?.data?.projectWiseData
            );
          } else {
            // setFirstTableCondition("project");
            ProjectCostingPdf(
              result?.data?.timeline,
              range ? range : "Current Month",
              // start
              //   ? `${start} ${t('till')} ${end}`
              //   : values.project
              //   ? result?.timeline?.[0]?.project?.title
              //   : "",
              values.project
                ? result?.data?.timeline?.[0]?.project?.title
                : t("assigned-projects"),
              result?.data?.timelineHours,
              Number(result?.data?.totalCost)?.toFixed(0),
              `${t("summary-report")}.pdf`,
              result?.data?.expensePerProject
            );
          }
        } else {
          notification.error({
            message: t("no-record-found"),
            duration: 5,
          });
        }
      }
      setLoader1(false);
      form.resetFields();
    } else {
      notification.warning({
        message: "Select atleast Resource or Project",
        duration: 5,
      });
    }
  };

  const onFinish2 = async (values) => {
    setLoader2(true);
    // let start;
    // let end;
    let range;
    if (values.date) {
      // start = moment(values.date[0]?._d).format("YYYY-MM-DD");
      // end = moment(values.date[1]?._d).format("YYYY-MM-DD");
      if (values.date === "currentMonth") {
        range = "Current Month";
      } else if (values.date === "lastMonth") {
        range = "Last Month";
      } else if (values.date === "last3Months") {
        range = "Last 3 Months";
      } else if (values.date === "last6Months") {
        range = "Last 6 Months";
      }
    }
    let payload = {
      // start: values.date ? start : null,
      // end: values.date ? end : null,
      date: values.date || "currentMonth",
    };
    const result = await getReportsAllProjects(payload);
    // setSecondTable(result);
    Object.entries(result?.result)?.map(([name, data]) => {
      ProjectCostingPdf(
        data?.timeline,
        name,
        name,
        data?.totalHours,
        Number(data?.totalCost)?.toFixed(0),
        name + ".pdf",
        result.expense
      );
    });
    setLoader2(false);
    form2.resetFields();
  };

  const onFinish3 = async (values) => {
    //getReportsAllEmployees

    setLoader3(true);
    // let start;
    // let end;
    let range;

    if (values.date) {
      // start = moment(values.date[0]?._d).format("YYYY-MM-DD");
      // end = moment(values.date[1]?._d).format("YYYY-MM-DD");
      if (values.date === "currentMonth") {
        range = "Current Month";
      } else if (values.date === "lastMonth") {
        range = "Last Month";
      } else if (values.date === "last3Months") {
        range = "Last 3 Months";
      } else if (values.date === "last6Months") {
        range = "Last 6 Months";
      }
    }
    let payload = {
      // start: values.date ? start : null,
      // end: values.date ? end : null,
      date: values.date || "currentMonth",
    };
    const result = await getReportsAllEmployees(payload);
    console.log(result);
    // setThirdTable(result);
    Object.entries(result)?.map(([name, data]) => {
      EmployeeCostingPdf(
        data?.timeline,
        "",
        data?.timeline?.[0]?.user || data.employee,
        data?.totalHours,
        Number(data?.totalCost)?.toFixed(0),
        name + ".pdf",
        // result.expense,
        data?.expensePerEmployee,
        data?.projectWiseData
      );
    });
    setLoader3(false);
    form3.resetFields();
  };
  const onProjectChange = (e) => {
    if (e === "default") {
      setSelected("");
      setSelectedProject(null);
    } else {
      setSelectedProject(e);
      dispatch(getEmployeesByProject(e));
      setSelected("project");
    }
  };
  const onEmployeeChange = (e) => {
    if (e === "default") {
      setSelected("");
      setSelectedEmployee(null);
    } else {
      setSelectedEmployee(e);
      dispatch(getProjectsByEmployee(e));
      setSelected("employee");
    }
  };
  return (
    <Layout active={"admin-report"}>
      {detail === null ? (
        <InfinityLoader />
      ) : (
        <div className="reports-main">
          <Typography.Title level={1}>
            {t(`generate-report-of-project-by-resource`)}
          </Typography.Title>
          {/* <Divider /> */}

          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            fields={[
              {
                name: "project",
                value: selectedProject,
              },
              {
                name: "user",
                value: selectedEmployee,
              },
            ]}
          >
            <Row gutter={[20, 20]} align="bottom">
              <Col xs={24} md={12} lg={6}>
                <Form.Item name="project" label={t("project")}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("select-project")}
                    onChange={onProjectChange}
                    value={selectedProject}
                  >
                    {selected === "project" && (
                      <Select.Option value={"default"}>
                        {t("default")}
                      </Select.Option>
                    )}

                    {selected === ""
                      ? projects?.map((project) => (
                          <Select.Option value={project._id}>
                            {project.title}
                          </Select.Option>
                        ))
                      : selected === "employee"
                      ? empProjects?.map((project) => (
                          <Select.Option value={project._id}>
                            {project.title}
                          </Select.Option>
                        ))
                      : selected === "project"
                      ? projects?.map((project) => (
                          <Select.Option value={project._id}>
                            {project.title}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item name="user" label={t("resource")}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={t("select-resource")}
                    value={selectedEmployee}
                    onChange={onEmployeeChange}
                  >
                    {selected === "employee" && (
                      <Select.Option value={"default"}>
                        {t("default")}
                      </Select.Option>
                    )}
                    {selected === ""
                      ? employees?.employee?.map((employee) => (
                          <Select.Option value={employee._id}>
                            {employee.name}
                          </Select.Option>
                        ))
                      : selected === "project"
                      ? projEmployees?.assignedTo?.map((employee) => (
                          <Select.Option value={employee._id}>
                            {employee.name}
                          </Select.Option>
                        ))
                      : selected === "employee"
                      ? employees?.employee?.map((employee) => (
                          <Select.Option value={employee._id}>
                            {employee.name}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item name="date" label={"Range"} required>
                  {/* <DatePicker.RangePicker
                    allowClear
                    disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  /> */}
                  <Select placeholder={"Select Range"}>
                    <Select.Option value="currentMonth">
                      Current Month
                    </Select.Option>
                    <Select.Option value="lastMonth">Last Month</Select.Option>
                    <Select.Option value="previous3Months">
                      Last 3 Months
                    </Select.Option>
                    <Select.Option value="previous6Months">
                      Last 6 Months
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loader1}>
                    {t("generate-report")}
                  </Button>
                </Form.Item>
              </Col>
              {firstTable &&
                (firstTableCondition === "employee" ? (
                  <Col span={24} className="report-table">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "center",
                      }}
                    >
                      <Typography.Title level={3}>
                        Employee Summary
                      </Typography.Title>
                      <EmployeeSummaryComponent
                        timeline={firstTable?.timeline}
                        month={firstTable?.month}
                        title={
                          firstStartDate
                            ? `${firstStartDate} - ${firstEndDate}`
                            : selectedProjectName
                            ? firstTable?.timeline?.[0]?.project?.title
                            : ""
                        }
                        employee={
                          firstTable?.timeline?.[0]?.user ||
                          firstTable?.employee
                        }
                        totalHours={firstTable?.totalHours}
                        totalCost={firstTable?.totalCost}
                        expense={firstTable?.expensePerEmployee}
                        projectWiseData={firstTable?.projectWiseData}
                      />
                    </div>
                  </Col>
                ) : (
                  <Col span={24} className="report-table">
                    First project Table Here
                  </Col>
                ))}
            </Row>
            <Divider />
          </Form>

          {/* one end */}

          <Typography.Title level={1} className="mt-7">
            {t("generate-report-of-all-projects-between-dates")}
          </Typography.Title>
          {/* <Divider /> */}

          <Form layout="vertical" onFinish={onFinish2} form={form2}>
            <Row gutter={[20, 20]} align="bottom">
              <Col xs={24} md={12} lg={6}>
                <Form.Item name="date" label={"Range"}>
                  {/* <DatePicker.RangePicker
                    allowClear
                    disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  /> */}
                  <Select placeholder={"Select Range"}>
                    <Select.Option value="currentMonth">
                      Current Month
                    </Select.Option>
                    <Select.Option value="lastMonth">Last Month</Select.Option>
                    <Select.Option value="previous3Months">
                      Last 3 Months
                    </Select.Option>
                    <Select.Option value="previous6Months">
                      Last 6 Months
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loader2}>
                    {t("generate-report")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
          </Form>

          {/* second end */}

          <Typography.Title level={1} className="mt-7">
            {t("generate-report-of-all-resources-between-dates")}
          </Typography.Title>
          {/* <Divider /> */}

          <Form layout="vertical" onFinish={onFinish3} form={form3}>
            <Row gutter={[20, 20]} align="bottom">
              <Col xs={24} md={12} lg={6}>
                <Form.Item name="date" label={"Range"}>
                  {/* <DatePicker.RangePicker
                    allowClear
                    disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  /> */}
                  <Select placeholder={"Select Range"}>
                    <Select.Option value="currentMonth">
                      Current Month
                    </Select.Option>
                    <Select.Option value="lastMonth">Last Month</Select.Option>
                    <Select.Option value="previous3Months">
                      Last 3 Months
                    </Select.Option>
                    <Select.Option value="previous6Months">
                      Last 6 Months
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loader3}>
                    {t("generate-report")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {/* <Divider /> */}
          </Form>
        </div>
      )}
    </Layout>
  );
};

export default ProjectDetail;

const EmployeeSummaryComponent = ({
  timeline,
  month,
  title,
  employee,
  totalHours,
  totalCost,
  expense,
  projectWiseData,
}) => {
  return <div></div>;
};
