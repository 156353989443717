import { useEffect, useState } from "react";
import { Typography, Input, Row, Col, Select, Pagination } from "antd";

import InfinityLoader from "../components/InfinityLoader";
import AttendanceCard from "../components/AttendanceCard";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAttendanceData, getDesignations } from "../redux";
import { getDepartments } from "../redux/actions/department";
import { useTranslation } from "react-i18next";
const Todos = () => {
  const { t } = useTranslation();
  const [Data, setData] = useState(null);
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.departments.departments);
  const designations = useSelector((state) => state.employees.designations);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [dept, setDept] = useState(null);
  const [desg, setDesg] = useState(null);
  const deptFilter = async (value) => {
    setDept(value);
  };
  const desgFilter = async (value) => {
    setDesg(value);
  };
  const paginate = (page) => {
    setCurrent(page);
    console.log(page);
  };
  useEffect(() => {
    dispatch(getDepartments());
    dispatch(getDesignations());
  }, []);
  useEffect(async () => {
    setLoading(true);
    const data = await getAttendanceData(dept, desg, current);
    setData(data);
    setLoading(false);
  }, [dept, desg, current]);

  return (
    <Layout active={"attendance"}>
      {loading === true ? (
        <InfinityLoader />
      ) : (
        <div className="todo-main">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>{t('employees')} {t('attendance')}</Typography.Title>
            <Row
              style={{
                width: "max-content",
              }}
              justify="space-between"
            >
              <Col md={11} sm={23}>
                <Select
                  placeholder={t('filter-by-department')}
                  onChange={deptFilter}
                  value={dept}
                  style={{
                    width: 200,
                    marginRight: 10,
                    marginBottom: 10,
                    float: "right",
                  }}
                >
                  <Select.Option value={"all"}>{t('all')}</Select.Option>
                  {departments?.departments?.map((val, ind) => {
                    return (
                      <Select.Option value={val._id}>{val.name}</Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <Col md={11} sm={23}>
                <Select
                  placeholder={t('filter-by-designation')}
                  onChange={desgFilter}
                  value={desg} 
                  style={{
                    width: 200,
                    marginRight: 10,
                    marginBottom: 10,
                    float: "right",
                  }}
                >
                  <Select.Option value={"all"}>{t('all')}</Select.Option>

                  {designations?.employee?.map((val, ind) => {
                    return <Select.Option value={val}>{val}</Select.Option>;
                  })}
                </Select>
              </Col>
            </Row>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 20,
              marginTop: 20,
              marginRight: 20,
            }}
          >
            <Pagination
              current={current}
              total={Data?.totalPages * 10}
              onChange={paginate}
            />
          </div>
          <div className="attendance-container">
            <Row className="projects" style={{ gap: 0 }} gutter={[20, 20]}>
              {Data &&
                Data?.response?.map((data) => (
                  // {[1, 2, 3, 4, 5, 6].map((data) => (
                  <Col xs={24} lg={12}>
                    <AttendanceCard Data={data} />
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Todos;
