import { GET_LOGS } from "../types/generalTypes";
import swal from "sweetalert";
import { notification } from "antd";
import { publicAPI, privateAPI, attachToken } from "../../config/constants";

export const getLogs = (date) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/logs/getLogs`, { date });
      if (res) {
        dispatch({
          type: GET_LOGS,
          payload: res.data,
        });
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};
