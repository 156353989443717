import Axios from "axios";

// export const base_url = "https://backend.internetforshare.com";
// export const base_url = "https://trackingbackend.herokuapp.com";
// export const base_url = "http://192.168.10.1:5000";
export const base_url = "https://backend.trackkrr.com";
// export const base_url = "http://localhost:5000";

export const publicAPI = Axios.create({ baseURL: base_url });

export const privateAPI = Axios.create({ baseURL: base_url });

export const attachToken = async () => {
  const jwt = localStorage.getItem("token");
  privateAPI.defaults.headers.common.Authorization = `Bearer ${jwt}`;
};
