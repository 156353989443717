import { Button, Col, DatePicker, notification, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ColumnChart from "../components/Charts/ColumnChart";
import LineChart from "../components/Charts/LineChart";
import InfinityLoader from "../components/InfinityLoader";
import ProjectCostingPdf from "../components/PdfTemplate/ProjectCostingPdf";
import Layout from "../layout/Layout";
import { useTranslation } from "react-i18next";
import {
  getAdminProject,
  getProjectCosting,
  getProjectDailyCosting,
} from "../redux";

const ProjectSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [DailyData, setDailyData] = useState(null);
  const [costingData, setcostingData] = useState(null);
  const [selectedYear, setselectedYear] = useState(moment().format("YYYY"));
  const [SelectedMonth, setSelectedMonth] = useState(moment().format("MMMM"));
  const [pdfMonth, setpdfMonth] = useState(null);
  const [PdfLoader, setPdfLoader] = useState(false);

  const GetData = async () => {
    let dailyResponse = await dispatch(getProjectDailyCosting(id));
    let dailyResponse2 = dailyResponse?.costing?.map((data) => {
      return {
        ...data,
        day: String(data.day),
      };
    });
    setDailyData({
      ...dailyResponse,
      costing: dailyResponse2,
    });

    const costResponse = await dispatch(getProjectCosting(id));
    setcostingData(costResponse);
  };

  useEffect(() => {
    GetData();
  }, []);

  const OnDailyDateChange = async (value, dateString) => {
    setpdfMonth(moment(value).format("MM"));
    setSelectedMonth(moment(value).format("MMMM"));
    const dailyResponse = await dispatch(
      getProjectDailyCosting(id, { month: moment(value).format("MM") })
    );
    let dailyResponse2 = dailyResponse?.costing?.map((data) => {
      return {
        ...data,
        day: String(data.day),
      };
    });
    setDailyData({
      ...dailyResponse,
      costing: dailyResponse2,
    });
  };
  const OnCostingDateChange = async (value, dateString) => {
    setselectedYear(moment(value).format("YYYY"));
    const costResponse = await dispatch(
      getProjectCosting(id, { year: moment(value).format("YYYY") })
    );
    setcostingData(costResponse);
  };

  const onProjectCostingPdf = async () => {
    setPdfLoader(true);
    let detail = await dispatch(
      getAdminProject({
        id: id,
        month: pdfMonth,
      })
    );
    if (detail?.timeline?.length !== 0) {
      ProjectCostingPdf(
        detail?.timeline,
        SelectedMonth,
        DailyData?.project?.title,
        DailyData?.project?.totalHours,
        DailyData?.project?.totalCost?.toFixed(0),
        "Project-Costing-Monthly-Report.pdf"
      );
      setPdfLoader(false);
    } else {
      notification.error({
        description: "No Entries Found",
        duration: 2,
      });
      setPdfLoader(false);
    }
  };
  function thousands_separator(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
    return x;
  }
  return (
    <Layout active={"admin-projects"}>
      {DailyData === null ? (
        <InfinityLoader />
      ) : (
        <div className="summary-container">
          <br />
          <Row gutter={[20, 40]}>
            <Col xs={24} md={12} xl={8}>
              <h2 className="label">
              {t('project-name')}: <span>{DailyData?.project?.title}</span>{" "}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className="label">
              {t('total-hours')}: <span>{DailyData?.project?.totalHours}</span>{" "}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className="label">
              {t('total-cost')}:{" "}
                <span>
                {t('rs')}{" "}
                  {thousands_separator(
                    DailyData?.project?.totalCost?.toFixed(0)
                  )}
                </span>{" "}
              </h2>
            </Col>
            <Col xs={24}>
              <h2 className="label">
              {t('description')}: <span>{DailyData?.project?.description}</span>{" "}
              </h2>
            </Col>
            <Col xs={24}>
              <div className="header">
                <h3 className="summaryHeading">
                  {SelectedMonth} {t('day-wise-project-costing')}
                </h3>
                <div>
                  {DailyData && (
                    <Button
                      type="primary"
                      style={{ marginRight: "1rem" }}
                      onClick={() => onProjectCostingPdf()}
                      loading={PdfLoader}
                    >
                      {t('download-pdf')}
                    </Button>
                  )}
                  <DatePicker
                    placeholder={t('select-month')}
                    onChange={OnDailyDateChange}
                    style={{ width: "200px" }}
                    picker={"month"}
                    disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>

              <div className="card">
                <LineChart
                  data={DailyData?.costing ? DailyData?.costing : []}
                  yField="cost"
                  xField="day"
                />
              </div>
            </Col>
            <Col xs={24}>
              <div className="header header2">
                <h3 className="summaryHeading ">
                {t('year')} {selectedYear} {t('monthly-costing')}
                </h3>
                <DatePicker
                  placeholder={t('select-year')}
                  onChange={OnCostingDateChange}
                  picker="year"
                  style={{ width: "200px" }}
                  disabledDate={(d) => {
                    return d?.isAfter(moment());
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                />
              </div>
              <div className="card card2">
                <ColumnChart
                  data={costingData?.costing ? costingData?.costing : []}
                  yField="cost"
                  xField="month"
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default ProjectSummary;
