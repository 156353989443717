import React, { useState, useForm } from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createDepartments } from "../../redux/actions/department";
import { useTranslation } from "react-i18next";
const AddDepartment = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };
  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(createDepartments(values));
    form.resetFields();
    setLoading(false);
    setIsModalVisible(false);
  };
  const onCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Button
        type="primary"
        className="addEmployeeBtn"
        onClick={showModal}
        style={{
          marginLeft: "10px",
        }}
      >
        {t("add-department")}
      </Button>
      <Modal
        title={t("add-department")}
        visible={isModalVisible}
        footer={null}
        onCancel={onCancel}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            label={t("department-name")}
            name="name"
            rules={[{ required: true, message: t("department-name-required") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ marginRight: "10px" }}
            >
              {t("submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddDepartment;
