import React from "react";
import { Line } from "@ant-design/plots";
import theme from "../../theme.json";

const LineChart = ({ data, xField, yField }) => {
  const config = {
    data,
    smooth: true,
    padding: "auto",
    xField: xField,
    yField: yField,
    color: theme["primary-color"],
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
  };

  return <Line {...config} />;
};

// loading={Arr.length > 0 ? false : true}
export default LineChart;
