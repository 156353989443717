import { Modal, Button, Select } from "antd";
import React from "react";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { deleteTodo } from "../../redux";
import { useTranslation } from "react-i18next";
const DeleteTodo = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const btnDelete = async () => {
    setLoading(true);
    const boolean = await dispatch(
      deleteTodo(props.data._id, reason, props.data.project)
    );
    if (boolean) {
      props.filter(props.data._id);
    }
    setLoading(false);
    setVisible(false);
  };

  return (
    <div>
      <Button
        style={{
          color: "var(--primary)",
          cursor: "pointer",
          position: "absolute",
          right: '-18px',
          top: "-55px",
          float: "right",
          background: "transparent",
          border: "none",
          padding: 0,
          fontSize: 15,
          backgroundColor: "white",
          borderRadius: "50%",
          height: "28px",
          width: "28px",
          fontWeight: "bold",
        }}
        onClick={() => {
          setVisible(true);
        }}
      >
        X
        {/* <AiOutlineDelete /> */}
      </Button>
      <Modal
        title={`${t('delete-task')}: ${props.data.title}`}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              marginBottom: 10,
              color: "var(--text)",
            }}
          >
            {t('are-you-sure-you-want-to-delete-this-task')}
          </p>
          <span
            style={{
              marginBottom: 10,
              color: "var(--text)",
            }}
          >
            {t('specify-the-reason')}
          </span>
          <Select
            placeholder={t('reason')}
            value={reason}
            onChange={(e) => {
              setReason(e);
            }}
            style={{ width: "80%", marginTop: 10 }}
          >
            <Select.Option value="repeated">{t('repeated')}</Select.Option>
            <Select.Option value="incorrect">{t('incorrect')}</Select.Option>
            <Select.Option value="not in scope">{t('not-in-scope')}</Select.Option>
          </Select>
          <Button
            type="primary"
            style={{
              marginTop: 20,
              width: "max-content",
              alignSelf: "center",
            }}
            loading={loading}
            onClick={btnDelete}
          >
            {t('delete')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteTodo;
