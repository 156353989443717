import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Badge, Button, Col, Layout, Row, Switch } from "antd";

import { MdOutlineDarkMode, MdDarkMode } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import WhiteLogo from "../assets/Logo.png";
import BlueLogo from "../assets/blueLogo.png";
import MainMenu from "./MainMenu";
import { toggleTheme } from "../redux";
import { FiBell, FiHome } from "react-icons/fi";
import { BiBellPlus } from "react-icons/bi";
import {
  getNotificationCount,
  getNotifications,
} from "../redux/actions/notifications";
import moment from "moment";
// import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const VerticalLayout = ({ children, active }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Header, Sider, Content } = Layout;
  const theme = useSelector((state) => state.theme.theme);
  const UserName = localStorage.getItem("userName");
  const { count } = useSelector((state) => state.notifications);
  const type = localStorage.getItem("userType");
  const [showNoti, setShowNoti] = useState(false);
  return (
    <div className="v-layout">
      <Layout>
        <Sider
          trigger={null}
          collapsible
          width={220}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            background: "var(--sidebar)",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <div className="company-logo">
            {theme === "light" ? (
              <img src={BlueLogo} alt="logo" />
            ) : (
              <img src={WhiteLogo} alt="logo" />
            )}
          </div>
          <MainMenu active={active} />
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: 220 }}>
          <Header className="mainHeader">
            <h3 className="company-title">{UserName}</h3>

            <div className="headerLft">
              {/* {type === "employee" && count?.unread > 0 && (
                <span className="notiCount">{count.unread}</span>
              )} */}
              {type === "employee" && (
                <Badge
                  count={count?.unread}
                  style={{
                    marginRight: "30px",
                  }}
                  className="notiBtn"
                >
                  <FiBell
                    className="notiIcon notiBtn"
                    onClick={() => {
                      setShowNoti(!showNoti);
                      dispatch(getNotifications());
                      dispatch(getNotificationCount());
                    }}
                    onBlur={() => {
                      setShowNoti(false);
                    }}
                  />
                </Badge>
              )}
              {showNoti && <NotificationComponent />}
              {type === "admin" && (
                <Badge
                  count={0}
                  style={{
                    marginRight: "30px",
                  }}
                  className="notiBtn"
                >
                  <BiBellPlus
                    className="notiIcon notiBtn"
                    onClick={() => {
                      history.push("/admin/createNotification");
                    }}
                  />
                </Badge>
              )}
              <Switch
                className="themeSwitch"
                defaultChecked={theme === "light" ? false : true}
                checkedChildren={
                  <MdDarkMode
                    style={{
                      fontSize: "16px",
                      verticalAlign: "middle",
                      marginRight: "5px",
                    }}
                  />
                }
                unCheckedChildren={
                  <MdOutlineDarkMode
                    style={{
                      fontSize: "16px",
                      verticalAlign: "middle",
                      marginLeft: "5px",
                    }}
                  />
                }
                onChange={() => dispatch(toggleTheme())}
              />
            </div>
          </Header>
          <Content className="mainContent">{children}</Content>
        </Layout>
      </Layout>
    </div>
  );
};

const NotificationComponent = () => {
  const { t } = useTranslation();
  const { notifications } = useSelector((state) => state.notifications);
  const history = useHistory();
  return (
    <div className="notiComp">
      <Row>
        <Col span={24}>
          <div className="notiHeader">
            <span>{t('notifications')}</span>
          </div>
        </Col>
        <div
          style={{
            minHeight: "310px",
            width: "100%",
          }}
        >
          {notifications?.notifications?.length > 0 ||
          notifications?.gNotifications?.length > 0 ? (
            <>
              {notifications?.gNotifications?.length > 0 ? (
                notifications.gNotifications.map((noti, ind) => {
                  return (
                    <Col
                      span={24}
                      className="notiCard"
                      key={ind}
                      style={{
                        height: "auto",
                        padding: "20px 0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span className="message">{noti.message}</span>
                        <span className="date">
                          {moment(noti.date).local().format("DD/MM HH:mm a")}
                        </span>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <div></div>
              )}
              {notifications?.notifications?.length > 0 ? (
                notifications.notifications.map((noti, ind) => {
                  return (
                    <Col span={24} className="notiCard" key={ind}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span className="message">{noti.message}</span>
                        <span className="date">
                          {moment(noti.date).local().format("DD/MM HH:mm a")}
                        </span>
                      </div>
                      {noti.type.type === "project" ||
                      noti.type.type === "todo" ? (
                        <Button
                          type="primary"
                          onClick={() => {
                            history.push(`/project/${noti.type.id}`);
                          }}
                          ant-click-animating-without-extra-node="false"
                        >
                          {t('view')}
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                  );
                })
              ) : (
                <div>{/* <span>No new specefic Notification</span> */}</div>
              )}
            </>
          ) : (
            <div>{t('no-new-notifications')}</div>
          )}

          {/* <Col span={24} className="notiCard">
            <span className="message">
              You are assigned a project by admin. Start working asap
            </span>
            <Button type='primary'>Project</Button>
          </Col>
          <Col span={24} className="notiCard">
            <span className="message">
              You are assigned a project by admin. Start working asap
            </span>
            <Button type='primary'>Project</Button>
          </Col>
          <Col span={24} className="notiCard">
            <span className="message">
              You are assigned a project by admin. Start working asap
            </span>
            <Button type='primary'>Project</Button>
          </Col>
          <Col span={24} className="notiCard">
            <span className="message">
              You are assigned a project by admin. Start working asap
            </span>
            <Button type='primary'>Project</Button>
          </Col> */}
        </div>
        <Col span={24}>
          <div className="notiFooter">
            <Link
              to={"/notifications"}
              style={{
                color: "var(--primary)",
              }}
            >
              {t('show-all-notifications')}
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerticalLayout;
