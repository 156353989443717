import * as types from "../types/generalTypes";

const initialState = {
  userTodos: null,
  projectTodos: null,
};

const cpuReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_TODOS:
      return {
        ...state,
        userTodos: payload,
      };
    case types.GET_PROJECT_TODOS:
      return {
        ...state,
        projectTodos: payload,
      };
    default:
      return state;
  }
};

export default cpuReducer;
