import { Row } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
const NotiCard = (props) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      className="notisCard"
    >
      <span>{t('type')}: {props.data.type?.type?.toUpperCase() || "General"}</span>
      <span>{t('message')}: {props.data.message}</span>
      <span>
      {t('date')}: {moment(props.data.date).local().format("DD/MM/YY HH:mm a")}
      </span>
    </div>
  );
};

export default NotiCard;
