import VerticalLayout from "./VerticalLayout";
import MobileLayout from "./MobileLayout";

const Layout = ({ children, active }) => {
  return (
    <div className="layout">
      <VerticalLayout children={children} active={active} />
      <MobileLayout children={children} active={active} />
    </div>
  );
};

export default Layout;
