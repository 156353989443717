import { Typography, Button, Divider, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AttendanceCard = ({ Data }) => {
  const { t } = useTranslation();
  const [detail, setDetail] = useState(false);
  let colors = [
    "orange",
    "red",
    "cyan",
    "green",
    "purple",
    "blue",
    "primary",
    "yellow",
  ];

  const randomLoop = (index) => {
    if (index >= colors.length) {
      return index % colors.length;
    } else {
      return index;
    }
  };
  const history = useHistory();

  return (
    <div
      className={`${
        colors[Math.floor(Math.random() * colors.length)]
      } attendance-card`}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span className="circle">{Data.employee.name[0]}</span>
        <div>
          <h3
            style={{
              margin: "0",
            }}
          >
            {Data.employee.name} ({Data.employee.designation})
          </h3>
          <h4
            style={{
              margin: "0",
            }}
          >
            {Data.employee.phone}
          </h4>
        </div>
      </div>

      <h4 style={{ fontWeight: "bold", marginLeft: "59px" }}>
        {t('checkin-time-today')} :{" "}
        {Data.todayAttendance.message ? (
          <span>{Data.todayAttendance.message}</span>
        ) : (
          <div
            style={{
              display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            <span>
              {t('local')}:{" "}
              {moment(Data.todayAttendance.start).local().format("hh:mm a")}{" "}
            </span>
            {Data.todayAttendance.zone &&
              moment.tz.guess(true) !== Data.todayAttendance.zone && (
                <span>
                  {t('employee')} ({Data.todayAttendance.zone}):{" "}
                  {moment(Data.todayAttendance.start)
                    .tz(Data.todayAttendance.zone)
                    .format("hh:mm a")}{" "}
                </span>
              )}
          </div>
        )}
      </h4>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <a
          // type="primary"
          onClick={() => {
            setDetail(!detail);
          }}
          style={{
            width: "max-content",
            height: "max-content",
            border: "none",
            fontDecoration: "underline",
          }}
        >
          {detail ? "Hide" : "Show details"}
        </a>
      </div>
      {detail && (
        <div>
          <h3>{t('last-7-days-checkin-and-hours')}</h3>
          <Table
            columns={[
              {
                title: t('date'),
                dataIndex: "date",
              },

              {
                title: t('hours'),
                dataIndex: "hours",
              },
            ]}
            pagination={false}
            size="small"
            scroll={{ x: "auto" }}
            dataSource={Data?.last7Day?.map((data) => ({
              date: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor:
                        data.hours <= 8 ? "lightgreen" : "var(--primary)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                    }}
                  >
                    {moment(data.date).local().format("dd")}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        fontSize: "10px",
                      }}
                    >
                      {moment(data.date).local().format("DD/MM/YYYY")}
                    </span>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        fontSize: "10px",
                      }}
                    >
                      {data.time === "-"
                        ? "---"
                        : `Local: ${moment(data.time.start)
                            .local()
                            .format("hh:mm a")}`}
                    </span>
                  </div>
                </div>
              ),
              hours: data.hours,
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default AttendanceCard;
