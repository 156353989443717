import swal from "sweetalert";
import { notification } from "antd";
import { publicAPI, privateAPI, attachToken } from "../../config/constants";
import * as types from "../types/generalTypes";

export const addTodo = (payload) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/todo/create`, payload);
      if (res) {
        dispatch(getUserTodos());

        notification.success({
          description: res.data.message,
          duration: 2,
        });
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const addProjectTodo = (payload) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/todo/project-create`, payload);
      if (res) {
        dispatch(getProjectTodos(payload.project));
        notification.success({
          description: res.data.message,
          duration: 2,
        });
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getUserTodos = () => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.get(`/todo/get-all`);
      if (res) {
        dispatch({
          type: types.GET_ALL_TODOS,
          payload: res.data,
        });
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getProjectTodos = (id) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.get(`/todo/get-all-project/${id}`);
      if (res) {
        dispatch({
          type: types.GET_PROJECT_TODOS,
          payload: res.data,
        });
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const updateTodoStatus = async (payload) => {
  try {
    const userId = localStorage.getItem("userId");
    payload = {
      ...payload,
      userId,
    };
    attachToken();
    const res = await privateAPI.post(`/todo/update`, payload);
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const deleteTodo = (id, reason, pid) => async (dispatch) => {
  try {
    const userId = localStorage.getItem("userId");
    attachToken();
    const res = await privateAPI.post(`/todo/delete/${id}`, {
      reason,
      userId,
    });
    if (res) {
      notification.success({
        description: res.data.message,
        duration: 2,
      });
      return true;
      // dispatch(getProjectTodos(pid));
    }
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const getDeletedTodos = (id) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.get(`/todo/deleted-project/${id}`);
      if (res) {
        console.log(res);
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const restoreTodo = (id, pid) => async (dispatch) => {
  try {
    attachToken();
    const res = await privateAPI.get(`/todo/restore/${id}`);
    if (res) {
      notification.success({
        description: res.data.message,
        duration: 2,
      });
      const data = await dispatch(getDeletedTodos(pid));
      // dispatch(getProjectTodos(pid));
      return data;
    }
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
