import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const ProjectCostingPdf = (
  projectData,
  month,
  title,
  totalHours,
  totalCost,
  pdfname,
  expense
) => {
  var doc = new jsPDF();

  doc.setTextColor("#F55139");
  doc.setFontSize(18);
  let textX =
    (doc.internal.pageSize.getWidth() -
      doc.getTextWidth(`${month} Day Wise Project Summary`)) /
    2;
  doc.text(`${month} Day Wise Project Summary`, textX, 16);

  doc.setTextColor("#000000");
  doc.setFontSize(14);
  doc.text(10, 35, "Project Name : ");
  doc.text(50, 35, `${title}`);

  doc.text(10, 45, "Total Hours : ");
  doc.text(45, 45, `${totalHours}`);

  doc.text(10, 55, "Total Cost : ");
  doc.text(42, 55, `Rs. ${totalCost}`);

  doc.text(128, 55, "Expense : ");
  doc.text(158, 55, `Rs. ${expense}`);

  //Header
  doc.line(10, 60, 190, 60);

  doc.line(10, 60, 10, 75);
  doc.line(50, 60, 50, 70);
  doc.line(135, 60, 135, 70);
  doc.line(160, 60, 160, 70);
  doc.line(190, 60, 190, 75);

  doc.line(10, 70, 190, 70);

  // // Coloumns Break

  doc.setFontSize(14);
  doc.text(17, 67, "Day");
  doc.text(53, 67, "Submit Time");
  doc.text(90, 67, "Employee");
  doc.text(140, 67, "Hours");
  doc.text(170, 67, "Cost");

  doc.setFontSize(13);
  var newXAxis = 71;

  projectData?.map((data) => {
    if (newXAxis >= 280) {
      doc.addPage();
      doc.line(10, 20, 190, 20);
      doc.line(10, 20, 10, 45);
      // doc.line(120, 20, 120, 45);

      //right sides
      doc.line(85, 20, 85, 45);
      doc.line(190, 20, 190, 45);

      newXAxis = 20;
    }
    newXAxis += 10;

    //   // bottom Line
    doc.line(10, newXAxis, 190, newXAxis);
    // Left Side Line
    doc.line(10, newXAxis, 10, 60);

    // Right Side Lines
    doc.line(50, 60, 50, newXAxis);
    doc.line(85, 60, 85, newXAxis);
    doc.line(135, 60, 135, newXAxis);
    doc.line(160, 60, 160, newXAxis);
    doc.line(190, 60, 190, newXAxis);

    doc.text(
      17,
      newXAxis - 3,
      `${moment(data?.CreatedAt).local().format("DD-MM-YYYY")}`
    );
    doc.text(57, newXAxis - 3, `${moment(data?.CreatedAt).format("hh:mm a")}`);
    doc.text(90, newXAxis - 3, `${data?.user?.name}`);
    doc.text(147, newXAxis - 3, `${data?.hours}`);
    doc.text(165, newXAxis - 3, "Rs. " + `${data?.cost?.toFixed(0)}`);
  });

  doc.save(pdfname);
  // doc.save(`Project-Costing-Monthly-Report.pdf`);
};

export default ProjectCostingPdf;
