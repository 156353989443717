import React from "react";
import loader from "../assets/loader.svg";
import '../styles/components/loader.css'
const InfinityLoader = () => {
  return (
    <div className="infinityLoader">
      <div class="middle">
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
        <div class="bar bar4"></div>
        <div class="bar bar5"></div>
        <div class="bar bar6"></div>
        <div class="bar bar7"></div>
        <div class="bar bar8"></div>
      </div>
    </div>
  );
};

export default InfinityLoader;