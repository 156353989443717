import { useEffect, useState } from "react";
import { Modal, Button, Form, Input, Row, Col, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createModerator, updateModerator } from "../../redux";
import { TbEdit } from "react-icons/tb";
import { getRoles } from "../../redux/actions/roles";
import { useTranslation } from "react-i18next";
const AddModerator = ({ moderator, data, roles }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (moderator) {
      await dispatch(createModerator(values));
    } else {
      dispatch(updateModerator(data._id, values));
    }
    setLoading(false);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  

  return (
    <>
      {moderator ? (
        <Button type="primary" className="addModeratorBtn" onClick={showModal}>
          {t('add-moderator')}
        </Button>
      ) : (
        <TbEdit className="editBtn" onClick={showModal} />
      )}
      <Modal
        title={moderator ? t('new-moderator') : t('update-moderator')}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={"600px"}
      >
        <Form
          layout="vertical"
          fields={
            data
              ? [
                  {
                    name: "name",
                    value: data.name,
                  },
                  {
                    name: "email",
                    value: data.email,
                  },
                ]
              : null
          }
          onFinish={onFinish}
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label={t('name')}
                name={"name"}
                rules={[
                  {
                    required: true,
                    message: t('name-required'),
                  },
                ]}
                requiredMark={"optional"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t('role')}
                name={"role"}
                rules={[
                  {
                    message: t('role-required'),
                  },
                ]}
              >
                <Select placeholder={t('select-role')}>
                  {roles?.moderatorRoles?.map((role) => {
                    return (
                      <Select.Option value={role.type}>
                        {role.type}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t('email')}
                name={"email"}
                rules={[
                  {
                    type: "email",
                    message: t('email-invalid'),
                  },
                  {
                    required: true,
                    message: t('email-required'),
                  },
                ]}
                requiredMark={"optional"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              {moderator && (
                <Form.Item
                  label={t('password')}
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: t('password-required'),
                    },
                  ]}
                  requiredMark={"optional"}
                >
                  <Input />
                </Form.Item>
              )}
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType="submit" loading={loading} type="primary">
              {moderator ? t('add-moderator') : t('update-moderator')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddModerator;
