import swal from "sweetalert";
import { publicAPI, privateAPI, attachToken } from "../../config/constants";
import * as types from "../types/generalTypes";

export const getDepartments = () => async (dispatch) => {
  try {
    attachToken();
    const res = await privateAPI.get("/departments/get-all");
    if (res) {
      dispatch({
        type: types.GET_DEPARTMENTS,
        payload: res.data,
      });
    }
  } catch (err) {
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const createDepartments = (data) => async () => {
  try {
    attachToken();
    const res = await privateAPI.post(`/departments/create`, data);
    if (res) {
      swal("", res?.data?.message);
    }
  } catch (err) {
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
