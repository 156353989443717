import * as types from "../types/generalTypes";

const initialState = {
  projects: null,
  tasks: null,
  checkins: null,
  projectDetails: {},
};

const cpuReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_PROJECTS:
      return {
        ...state,
        projects: payload,
      };
    case types.GET_ALL_TASKS:
      return {
        ...state,
        tasks: payload,
      };
    case types.GET_CHECKINS:
      return {
        ...state,
        checkins: payload,
      };
    case types.GET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: payload,
      };
    default:
      return state;
  }
};

export default cpuReducer;
