import axios from "axios";
import swal from "sweetalert";
import { notification } from "antd";
import { publicAPI, privateAPI, attachToken } from "../../config/constants";
import * as types from "../types/generalTypes";

export const getAllProjects = () => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.get(`/project/get-all`);
      if (res) {
        dispatch({
          type: types.GET_ALL_PROJECTS,
          payload: res.data,
        });
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getAdminProject = (data) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/project/get`, data);
      if (res) {
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getUserProject = (data) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/project/get-employee`, data);
      if (res) {
        dispatch({
          type: types.GET_PROJECT_DETAILS,
          payload: res.data,
        });
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getProjectUsers = (id) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.get(`/project/project-users/${id}`);
      if (res) {
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const createProject = (values) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/project/create`, values);
      if (res) {
        dispatch(getAllProjects());
        notification.success({
          description: res.data.message,
          duration: 2,
        });
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const updateProject = (values) => {
  return async (dispatch) => {
    try {
      const res = await publicAPI.post(`/project/update`, values);
      if (res) {
        notification.success({
          description: res.data.message,
          duration: 2,
        });
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getAllEmployeeProjects = () => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.get(`/project/get-all-employee`);
      if (res) {
        dispatch({
          type: types.GET_ALL_PROJECTS,
          payload: res.data,
        });
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getProjectCosting = (projectId, data) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(
        `/project/get-costing/${projectId}`,
        data
      );
      if (res) {
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getProjectDailyCosting = (projectId, data) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(
        `/project/get-daily-costing/${projectId}`,
        data
      );
      if (res) {
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const getByResource = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/report/get-by-resource`, payload);
    if (res) {
      return res.data;
    }
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const getReportsAllProjects = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/report/get-all-projects`, payload);
    if (res) {
      return res.data;
    }
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const getReportsAllEmployees = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/report/get-all-employees`, payload);
    if (res) {
      return res.data;
    }
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const getUserTimelineScreenshots = () => {
  return async (dispatch) => {
    try {
      let res;
      attachToken();
      if (localStorage.getItem("userType") === "employee") {
        res = await privateAPI.get(`/screenshot/get-user-check-ins`);
      } else {
        res = await privateAPI.get(`/screenshot/get-user-check-ins-m`);
      }
      if (res) {
        dispatch({
          type: types.GET_CHECKINS,
          payload: res.data,
        });
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const startTask = (cordinates, zone, userId) => {
  return async (dispatch) => {
    try {
      let res;
      attachToken();
      if (localStorage.getItem("userType") === "employee") {
        res = await privateAPI.post(`/screenshot/start`, {
          cordinates,
          zone,
        });
      } else {
        res = await privateAPI.post(`/screenshot/start-m`, {
          cordinates,
          zone,
        });
      }
      if (res) {
        dispatch(getUserTimelineScreenshots());
        localStorage.setItem("checkinId", res.data.doc._id);
        notification.success({
          description: res.data.message,
          duration: 2,
        });
        let allDetails = await getUserDailyDetails({ user: userId });
        return allDetails;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const endTask = (force, userId) => {
  return async (dispatch) => {
    try {
      let res;
      attachToken();
      if (localStorage.getItem("userType") === "employee") {
        res = await privateAPI.post(`/screenshot/end`, { force });
      } else {
        res = await privateAPI.post(`/screenshot/end-m`, { force });
      }
      if (res) {
        dispatch(getUserTimelineScreenshots());
        localStorage.removeItem("checkinId");

        notification.success({
          description: res.data.message,
          duration: 2,
        });
        let allDetails = await getUserDailyDetails({ user: userId });
        return allDetails;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const addScreenshot = async (formdata, payload, setLocalFunction) => {
  try {
    attachToken();
    if (localStorage.getItem("userType") === "employee") {
      await privateAPI.post(`/screenshot/add-screenshot`, formdata);
    } else {
      await privateAPI.post(`/screenshot/add-screenshot-m`, formdata);
    }
  } catch (err) {
    setLocalFunction(payload);
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const addOfflineScreenshot = async (payload) => {
  try {
    let res;
    attachToken();
    if (localStorage.getItem("userType") === "employee") {
      res = await privateAPI.post(
        `/screenshot/add-offline-screenshot`,
        payload
      );
    } else {
      res = await privateAPI.post(
        `/screenshot/add-offline-screenshot-m`,
        payload
      );
    }
    if (res) {
      localStorage.setItem("offlineScreenshots", JSON.stringify([]));
    }
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const getUserScreenshots = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(`/screenshot/get-screenshot`, payload);
    if (res) {
      return res.data;
    }
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};

export const getUserDailyDetails = async (payload) => {
  try {
    attachToken();
    const res = await privateAPI.post(
      `/screenshot/getuserdailydetails`,
      payload
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    //console.log(err);
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
