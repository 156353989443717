import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";

import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import AdminProjects from "../pages/AdminProject";
import AdminEmployees from "../pages/AdminEmployee";
import ProjectDetail from "../pages/ProjectDetail";
import UserProject from "../pages/UserProject";
import Moderators from "../pages/Moderator";
import ProjectSummary from "../pages/ProjectSummary";
import EmployeeSummary from "../pages/EmployeeSummary";
import SummaryOwnEmployee from "../pages/SummaryOwnEmployee";
import Reports from "../pages/Reports";
import PrivateRoutes from "./PrivateRoutes";
import Calendar from "../pages/Calendar";
import Todos from "../pages/Todos";
import EmployeeScreenshots from "../pages/EmployeeScreenshots";
import EmployeeAttendance from "../pages/EmployeeAttendance";
import Roles from "../pages/Roles.js";
import Expenses from "../pages/Expenses";
import Notifications from "../pages/Notifications.js";
import { useState } from "react";
import Logs from "../pages/Logs";
import CreateNotifications from "../pages/CreateNotifications.js";
import DeletedTodos from "../pages/DeletedTodos";
import EmployeeCheckins from "../pages/Checkins.js";
import AdminRoutes from "./AdminRoutes";
import EmployeeRoutes from "./EmployeeRoutes";
import ForgotPassword from "../pages/ForgotPassword.js";
import ResetPassword from "../pages/ResetPassword.js";

const Routes = () => {
  const [intervalState, setIntervalState] = useState(null);
  const [videoEL, setVideoEl] = useState(null);
  const [TrackState, setTrackState] = useState(null);
  const type = localStorage.getItem("userType");
  const moderator = JSON.parse(localStorage.getItem("moderator"));
  const roles = moderator?.roles?.roles;
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {/* General Route */}
          <Route path="/" exact component={Login} />
          <Route path="/forgotPassword" exact component={ForgotPassword} />
          <Route path="/resetPassword" exact component={ResetPassword} />
          <PrivateRoutes
            path="/moderator/checkins"
            exact
            component={() => {
              return (
                <EmployeeCheckins
                  intervalState={intervalState}
                  setIntervalState={setIntervalState}
                  videoEL={videoEL}
                  setVideoEl={setVideoEl}
                  TrackState={TrackState}
                  setTrackState={setTrackState}
                />
              );
            }}
          />
          {console.log("type", type)}
          {/* Admin Route */}
          {type !== "moderator" && (
            <>
              {/* Admin Routes */}
              <>
                <AdminRoutes path="/dashboard" exact component={Dashboard} />
                <AdminRoutes
                  path="/project/:id"
                  exact
                  component={ProjectDetail}
                />
                <AdminRoutes path="/calendar" exact component={Calendar} />

                {/* Admin AdminRoutes */}
                <AdminRoutes
                  path="/admin/dashboard"
                  exact
                  component={Dashboard}
                />
                <AdminRoutes
                  path="/admin/projects"
                  exact
                  component={AdminProjects}
                />
                <AdminRoutes
                  path="/admin/project/:id"
                  exact
                  component={ProjectDetail}
                />
                <AdminRoutes
                  path="/admin/project/summary/:id"
                  exact
                  component={ProjectSummary}
                />
                <AdminRoutes
                  path="/admin/employees"
                  exact
                  component={AdminEmployees}
                />
                <AdminRoutes
                  path="/admin/attendance"
                  exact
                  component={EmployeeAttendance}
                />
                <AdminRoutes
                  path="/admin/checkins"
                  exact
                  component={EmployeeScreenshots}
                />
                <AdminRoutes
                  path="/admin/employee/summary/:id"
                  exact
                  component={EmployeeSummary}
                />
                <AdminRoutes
                  path="/admin/moderators"
                  exact
                  component={Moderators}
                />
                <AdminRoutes path="/admin/reports" exact component={Reports} />
                <AdminRoutes
                  path="/admin/expenses"
                  exact
                  component={Expenses}
                />

                <AdminRoutes path="/admin/roles" exact component={Roles} />
                <AdminRoutes path="/admin/logs" exact component={Logs} />

                <AdminRoutes
                  path="/admin/createNotification"
                  exact
                  component={CreateNotifications}
                />
                <AdminRoutes
                  path="/admin/deleted-todos/:id"
                  exact
                  component={DeletedTodos}
                />
              </>
              {/* Employee Routes */}
              <>
                <EmployeeRoutes
                  path="/employee/projects"
                  exact
                  component={UserProject}
                />
                <EmployeeRoutes path="/todo" exact component={Todos} />
                <EmployeeRoutes
                  path="/project-e/:id"
                  exact
                  component={ProjectDetail}
                />
                <EmployeeRoutes
                  path="/employee/summary"
                  exact
                  component={SummaryOwnEmployee}
                />
                <EmployeeRoutes
                  path="/notifications"
                  exact
                  component={Notifications}
                />
                <EmployeeRoutes
                  path="/employee/checkins"
                  exact
                  component={() => {
                    return (
                      <EmployeeCheckins
                        intervalState={intervalState}
                        setIntervalState={setIntervalState}
                        videoEL={videoEL}
                        setVideoEl={setVideoEl}
                        TrackState={TrackState}
                        setTrackState={setTrackState}
                      />
                    );
                  }}
                />
              </>
            </>
          )}

          {/* Moderator PrivateRoutes */}
          {type === "moderator" && (
            <>
              <PrivateRoutes path="/todo" exact component={Todos} />
              <PrivateRoutes
                path="/project/:id"
                exact
                component={ProjectDetail}
              />
              <PrivateRoutes path="/calendar" exact component={Calendar} />

              {/* Admin PrivateRoutes */}
              <PrivateRoutes
                path="/admin/dashboard"
                exact
                component={Dashboard}
              />
              {roles?.includes("projects") && (
                <>
                  <PrivateRoutes
                    path="/admin/projects"
                    exact
                    component={AdminProjects}
                  />
                  <PrivateRoutes
                    path="/admin/project/:id"
                    exact
                    component={ProjectDetail}
                  />
                  <PrivateRoutes
                    path="/admin/project/summary/:id"
                    exact
                    component={ProjectSummary}
                  />
                </>
              )}
              {roles?.includes("employees") && (
                <>
                  <PrivateRoutes
                    path="/admin/employees"
                    exact
                    component={AdminEmployees}
                  />
                  <PrivateRoutes
                    path="/admin/employee/summary/:id"
                    exact
                    component={EmployeeSummary}
                  />
                  <PrivateRoutes
                    path="/employee/summary"
                    exact
                    component={SummaryOwnEmployee}
                  />
                </>
              )}
              {roles?.includes("attendance") && (
                <PrivateRoutes
                  path="/admin/attendance"
                  exact
                  component={EmployeeAttendance}
                />
              )}
              {roles?.includes("checkins") && (
                <PrivateRoutes
                  path="/admin/checkins"
                  exact
                  component={EmployeeScreenshots}
                />
              )}
              {roles?.includes("moderators") && (
                <PrivateRoutes
                  path="/admin/moderators"
                  exact
                  component={Moderators}
                />
              )}
              {roles?.includes("reports") && (
                <PrivateRoutes
                  path="/admin/reports"
                  exact
                  component={Reports}
                />
              )}
              {roles?.includes("expenses") && (
                <PrivateRoutes
                  path="/admin/expenses"
                  exact
                  component={Expenses}
                />
              )}

              {roles?.includes("roles") && (
                <PrivateRoutes path="/admin/roles" exact component={Roles} />
              )}
            </>
          )}
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;

