import { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Link,
  useHistory,
  Redirect,
  NavLink,
  useLocation,
} from "react-router-dom";
import swal from "sweetalert";

import { authLogin, forgotPassword, resetPassword } from "../redux";
import Header from "../components/Header";
import logo from "../assets/Logo.png";

const ResetPassword = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    if (values.password !== values.confirmPassword) {
      swal("Error", "Password and Confirm Password do not match", "error");
      setLoading(false);
      return;
    }
    const bool = await resetPassword({
      email: location.state.email,
      token: values.token,
      password: values.password,
    });
    setLoading(false);
    if (bool) {
      history.push("/");
    }
  };
  let type = localStorage.getItem("userType");

  if (localStorage.hasOwnProperty("token") && type === "employee") {
    return <Redirect to="/employee/checkins" />;
  } else if (
    localStorage.hasOwnProperty("token") &&
    (type === "admin" || type === "moderator")
  ) {
    return <Redirect to="/admin/projects" />;
  } else {
    return (
      <>
        <div className="signin">
          <Form
            name="login"
            className="login-form"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            {/* <img src={logo} / > */}
            <img
              src={logo}
              style={{
                width: 200,
                // marginRight: 20,
              }}
            />
            <h2>{t('reset-password')}</h2>
            {/* <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 20,
                      }}>
                        <div style={{
                          width: "76%",
                        }}>
                        </div>
                      </div> */}
            <Form.Item
              name="token"
              rules={[
                {
                  required: true,
                  message: t("token-required"),
                },
              ]}
              label={t("reset-token")}
            >
              <Input autoComplete="off" placeholder={t('reset-token')} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("password-required"),
                },
              ]}
              label={t("password")}
            >
              <Input type="password" placeholder={t('password')} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: t("confirm-password-required"),
                },
              ]}
              label={t("confirm-password")}
            >
              <Input type="password" placeholder={t('confirm-password')} />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {t("reset")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  }
};

export default ResetPassword;
