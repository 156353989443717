import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources/translation.js";

let language = localStorage.getItem("language");

i18n.use(initReactI18next).init({
  resources,
  lng: language ? language : "en",
  fallbackLng: "en",
});

export default i18n;
