import swal from "sweetalert";
import { notification } from "antd";
import { publicAPI, privateAPI, attachToken } from "../../config/constants";

export const createRemarks = (values) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/timeline/create`, values);
      if (res) {
        notification.success({
          description: res.data.message,
          duration: 2,
        });
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};
export const updateRemarks = (values) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/timeline/update`, values);
      if (res) {
        notification.success({
          description: res.data.message,
          duration: 2,
        });
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};

export const deleteRemark = (values) => {
  return async (dispatch) => {
    try {
      attachToken();
      const res = await privateAPI.post(`/timeline/delete`, values);
      if (res) {
        notification.success({
          description: res.data.message,
          duration: 2,
        });
        return res.data;
      }
    } catch (err) {
      //console.log(err);
      swal("", err?.response?.data?.message || "Server Error", "error");
    }
  };
};
